<template>
  <v-container fluid class="pa-0" style="position: absolute; top: 80px">
    <v-row
        class="ma-0 pa-0"
    >
      <v-col
          cols="10"
          offset="1"
      >
        <v-card class="pa-3 mt-3" min-width="380">
          <p class="v-card-title mb-6">Статистика пользователей</p>
          <v-sheet
              class="d-flex flex-wrap"
          >
            <v-sheet
                v-for="(value, name) in stat" :key="name"
                class="ma-2 pa-2 elevation-3"
            >
              {{ name }}
              <v-slider
                  v-model="stat[name]"
                  min="0"
                  max="10000"
                  step="1"
                  thumb-label="always"
                  readonly
                  style="min-width: 250px!important"
              ></v-slider>
            </v-sheet>
          </v-sheet>
        </v-card>
        <v-card class="mt-3">
          <div class="pa-3" v-if="!loading" v-html="prepareText(msgs[msgIndex].text, msgs[msgIndex].NER)">
          </div>
          <v-progress-circular
              v-else
              :size="50"
              color="primary"
              indeterminate
          ></v-progress-circular>
          <v-divider></v-divider>
          <v-card v-if="msgs[msgIndex].NER" class="d-flex flex-wrap">
            <v-card
              class="mx-auto ma-6"
              min-width="250"
              max-width="500"
              outlined
              v-for="ner in msgs[msgIndex].NER" :key="ner.start"
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">
                    {{ ner.type}}
                  </div>
                  <v-list-item-title class="text-h5 mb-1">
                    {{ ner.word }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ ner.lemma }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-card-actions>
                <v-select
                  v-model="ner.sentiment"
                  :items="['Позитивное','Нейтральное','Негативное']"
                  label="Отношение"
                ></v-select>
              </v-card-actions>
            </v-card>
            <v-btn block=""
                  color="info" v-on:click="setTags(msgs[msgIndex].id, msgs[msgIndex].NER)">Сохранить</v-btn>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
// import spheresThemes from '../assets/spheresThemes.json'

let apiUrl = process.env.VUE_APP_API_LINK

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array
}

if (String.prototype.splice === undefined) {
  /**
   * Splices text within a string.
   * @param {int} offset The position to insert the text at (before)
   * @param {string} text The text to insert
   * @param {int} [removeCount=0] An optional number of characters to overwrite
   * @returns {string} A modified string containing the spliced text.
   */
  String.prototype.splice = function(offset, text, removeCount=0) {
    let calculatedOffset = offset < 0 ? this.length + offset : offset;
    return this.substring(0, calculatedOffset) +
      text + this.substring(calculatedOffset + removeCount);
  };
}

// function sortByProcessedUserCount(array) {
//   array.sort((a, b) => {
//     let aCount = 0;a.values().forEach(val => {if(val!=null){aCount++}});
//     let bCount = 0;b.values().forEach(val => {if(val!=null){bCount++}});
//     return bCount - aCount;
//   })
//   return array;
// }

export default {
  name: "AdminRef",
  data: () => ({
    stat: {},
    msgs: [{text: "Идет загрузка..."}],
    msgIndex: 0,
    loading: false,
    spheresThemes: []
  }),
  methods: {
    prepareText: function (text, ner){
      if (ner) {
        let swing = 0
        for (let i in ner) {
          let word = ner[i]
          text = text.splice(word.start + swing, "<b>")
          swing = swing + 3
          text = text.splice(word.end + swing, "</b>")
          swing = swing + 4
        }
        return text
      } else {
        return text
      }
    },
    getMsgs: function () {
      this.loadingState = true
      this.topics = []
      axios.get(apiUrl + "/getCheckMsgs?ner=True", {headers: {"Authorization": `Bearer ${this.$store.state.token}`}})
          .then(resp => {
            this.msgs = shuffleArray(resp.data).filter(msg => msg.NER !== "[]").filter(msg => msg.NER !== null);
            this.msgIndex = this.getMsgIndex()
            this.msgs[this.msgIndex].NER = JSON.parse(this.msgs[this.msgIndex].NER)
            // console.log(this.getMsgIndex())
          })
    },
    getStat: function () {
      this.loadingState = true
      this.topics = []
      axios.get(apiUrl + "/getCheckStat?ner=True", {headers: {"Authorization": `Bearer ${this.$store.state.token}`}})
          .then(resp => {
            this.stat = resp.data
          })
    },
    setTags: function (id, tags) {
      console.log(tags)
      this.loading = true
      let jsonString = JSON.stringify(tags).replaceAll('"', "'")
      axios({
        url: apiUrl + '/addTagForCheck?ner=True',
        headers: {"Authorization": `Bearer ${this.$store.state.token}`},
        method: 'POST',
        data: {
          user: this.$store.state.user,
          tag: jsonString,
          id: id,
        }
      })
          .then(resp => {
            this.msgs[this.msgIndex]["NER_" + this.$store.state.user] = jsonString
            this.loading = false
            this.getStat()
            this.msgIndex = this.getMsgIndex()
            this.msgs[this.msgIndex].NER = JSON.parse(this.msgs[this.msgIndex].NER)
            console.log(resp)
          })
          .catch(err => {
            console.log(err)
          })
    },
    getMsgIndex: function () {
      let arr = this.msgs
      let user = this.$store.state.user
      return arr.findIndex(x => x["NER_" + user] == null)
    },
  },
  mounted() {
    this.getMsgs()
    this.getStat()
  },
  watch: {}
}
</script>

<style scoped>

</style>