<template>
  <v-container fluid class="pa-0" style="position: absolute; top: 80px">
    <v-row
        class="ma-0 pa-0"
    >
      <v-col
          cols="10"
          offset="1"
      >
        <v-card class="mt-3 pa-3">
          <v-file-input
              :rules="rules"
              v-model="file"
              v-if="!msgText"
              accept=".csv"
              label="Файл"
              class="pt-5"
          ></v-file-input>
          <v-btn block color="blue-accent-1" class="elevation-3" v-on:click="setTag" v-if="file.length || selectedSphere">Сохранить</v-btn>
        </v-card>
        <v-card class="mt-3 pa-3" v-if="spheresForUsers.length > 0">
          <v-table>
            <thead>
            <tr>
              <th class="text-left">
                Пользователь
              </th>
              <th class="text-left">
                Активные сферы
              </th>
              <th class="text-left">
                Отключенные сферы
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="user in spheresForUsers"
                :key="user.user"
            >
              <td>{{ user.user }}</td>
              <td>
                <v-chip-group
                    column
                >
                  <v-chip
                      v-for="sphere in user.spheres.split(',')"
                      :key="sphere"
                      v-on:click="updateSphere(sphere, user.user, 'remove')"
                  >
                    {{ sphere }}
                  </v-chip>
                </v-chip-group>
              </td>
              <td>
                <v-chip-group
                    column
                >
                  <v-chip
                      v-for="sphere in allSpheres.filter(x => !user.spheres.split(',').includes(x))"
                      :key="sphere"
                      v-on:click="updateSphere(sphere, user.user, 'add')"
                  >
                    {{ sphere }}
                  </v-chip>
                </v-chip-group>
              </td>
            </tr>
            </tbody>
          </v-table>
        </v-card>
        <v-card class="mt-3 pa-3" v-if="allMsgs.length > 0">
          <v-table>
            <thead>
            <tr>
              <th class="text-left">
                Текст
              </th>
              <th class="text-left">
                Сферы
              </th>
              <th class="text-left">
                Темы
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="msg in allMsgs"
                :key="msg.msg_id"
            >
              <td>{{ msg.text }}</td>
              <td>
                <v-chip-group
                    column
                >
                  <v-chip
                      v-for="sphere in msg.spheres.split(',')"
                      :key="sphere"
                  >
                    {{ sphere }}
                  </v-chip>
                </v-chip-group>
              </td>
              <td>
                <v-chip-group
                    column
                >
                  <v-chip
                      v-for="theme in msg.themes.split(',')"
                      :key="theme"
                  >
                    {{ theme }}
                  </v-chip>
                </v-chip-group>
              </td>
            </tr>
            </tbody>
          </v-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'

let apiUrl = process.env.VUE_APP_API_LINK

export default {
  name: "AdminRef",
  data: () => ({
    rules: [
      value => {
        return !value || !value.length || value[0].size < 5000000 || 'Файл должен быть меньше 5 MB!'
      },
    ],
    limit: 10,
    maxPage: 1,
    page: 1,
    file: {},
    stat: [],
    msgText: "",
    allMsgs: [],
    allSpheres: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14'],
    spheresForUsers: []
  }),
  methods: {
    updateSphere: function (sphere, user, action) {
      axios({
        url: apiUrl + '/updateSpheresV2',
        headers: {"Authorization": `Bearer ${this.$store.state.token}`},
        method: 'POST',
        data: {
          user: user,
          sphere: sphere,
          action: action
        }
      })
          .then(resp => {
            console.log(resp)
            this.getSpheresForUsers()
          })
          .catch(err => {
            console.log(err)
            this.getSpheresForUsers()
          })
    },
    getAllMsgs: function () {
      axios({
        url: apiUrl + '/getAllMsgsForCheck2',
        headers: {"Authorization": `Bearer ${this.$store.state.token}`},
        method: 'GET'
      })
          .then(resp => {
            console.log(resp)
            this.allMsgs = resp.data
          })
          .catch(err => {
            console.log(err)
          })
    },
    getSpheresForUsers: function () {
      axios({
        url: apiUrl + '/getSpheresForUsers2',
        headers: {"Authorization": `Bearer ${this.$store.state.token}`},
        method: 'GET'
      })
          .then(resp => {
            this.spheresForUsers = resp.data
          })
          .catch(err => {
            console.log(err)
          })
    },
    setTag: function () {
      if (this.file.length) {
        const blob = new Blob(this.file)
        blob.text().then(data => {
          axios({
            url: apiUrl + '/uploadCheckMsgsV2',
            headers: {"Authorization": `Bearer ${this.$store.state.token}`},
            method: 'POST',
            data: {
              user: this.$store.state.user,
              data: data
            }
          })
              .then(resp => {
                console.log(resp)
                this.file = {}
              })
              .catch(err => {
                console.log(err)
                this.file = {}
              })
        })
      }
    }
  },
  mounted() {
    this.getAllMsgs()
    this.getSpheresForUsers()
  },
  watch: {
  }
}
</script>

<style>
.whiteText {
  color: white !important;
}
</style>