<script>

import MarkupApi from "@/plugins/markup_api";
import VueEvents from "@/plugins/VueEvents";


export default {
    name: "MarkupBase",
    data: () => ({
        markup_type: 'empty',
        message: null,
        previous_markup_id: null,
        previous_markup_value: null,
        is_loaded: false,
        markup: null,
        events: new VueEvents(),
        instruction: "",
    }),
    methods: {
        checkLoadState: function () {
            if (this.message != null) {
                this.is_loaded = true;
            }
        },
        loadMessage: function (markup_id = null) {
            this.is_loaded = false;
            MarkupApi.get("message", this.$store.state.token, {type: this.markup_type, markup_id: markup_id})
                .then(resp => {
                    this.message = resp.data;
                    this.events.on("messageLoaded");
                    this.loadStatistics();
                    this.checkLoadState();
                }).catch(reason => {
                console.log(reason);
                this.errorAlert(8731798164);
            });
        },
        loadStatistics: function () {
            this.$refs.statistics.loadStatistics();
        },
        loadInstruction: function () {
            MarkupApi.get("instructions", this.$store.state.token, {type: this.markup_type})
                .then(resp => {
                    console.log('instructons', resp.data)
                    this.instruction = resp.data.text;
                }).catch(reason => {
                console.log(reason);
                this.errorAlert(6491941036);
            });
        },
        setMarkup: function (value) {
            if (this.message.markup_id == null)
                return;
            this.is_loaded = false;
            MarkupApi.post("set_markup", this.$store.state.token, {
                markup_id: this.message.markup_id,
                value: value
            })
                .then(resp => {
                    if (resp.status) {
                        this.previous_markup_id = this.message.markup_id;
                        this.previous_markup_value = value;
                        this.loadMessage();
                    } else {
                        this.errorAlert(7208210447);
                    }
                }).catch(reason => {
                console.log(reason);
                this.errorAlert(3770840970);
            });
        },
        moveBack: function () {
            if (this.previous_markup_id == null) {
                this.errorAlert(6158737219);
                return;
            }
            let msg_id = this.previous_markup_id;
            this.previous_markup_id = null;

            this.loadMessage(msg_id);
        },
        switchPreviousMarkupIfExists: function () {
            if (this.message && this.message.condition) {
                if (this.previous_markup_id == null && this.previous_markup_value) {
                    this.markup = this.previous_markup_value;
                } else {
                    this.markup = this.defaultMarkup();
                }
            }
        },
        defaultMarkup: function () {return null},
        errorAlert: function (err_code) {
            this.$refs.alerts.addAlert(
                "error",
                `Ошибка #${err_code}`,
                'Пожалуйста свяжитесь с поддержкой.'
            );
        }
    },
    mounted() {
        this.events.addCallback("messageLoaded", this.switchPreviousMarkupIfExists);
        this.loadMessage();
        this.loadInstruction();
        this.markup = this.defaultMarkup();
    },
    watch: {}
}
</script>


<style>
.v-chip {
    white-space: break-spaces;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    height: auto !important;
}
</style>