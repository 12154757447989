<template>
  <v-container fluid class="pa-0">
    <v-row
        class="ma-0 pa-0"
    >
      <v-col cols="7" class="pa-3">
        <v-img :src="require('../assets/logo_rt.svg')" max-height="75vh" max-width="600" position="top left"
               v-if="activeRegion.type === 'nrt' && !showAdmin"></v-img>
        <v-img :src="require('../assets/logo.svg')" max-height="75vh" max-width="300" position="top left"
               v-else-if="activeRegion.type === 'n' || showAdmin"></v-img>
      </v-col>
      <v-col
          cols="2"
      >
        <v-select
            :items="editor"
            item-title="name"
            item-value="id"
            v-model="selectAdmin"
            label="Админка"
            variant="outlined"
            v-if="editor.length > 0"
        ></v-select>
      </v-col>
      <v-col
          cols="2"
      >
        <v-select
            :items="regionsList"
            item-title="name"
            item-value="id"
            v-model="selectRegion"
            label="Дэшборд"
            variant="outlined"
            v-if="regionsList.length > 1 || editor.length > 0"
        ></v-select>
      </v-col>
      <v-col
          cols="1"
      >
        <v-btn v-on:click="logout">ВЫЙТИ
          <v-icon
              right
              dark
          >
            mdi-logout
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
  <v-container fluid class="pa-0" style="position: absolute; top: 80px" v-if="!showAdmin && activeRegion.board">
    <v-row
        class="ma-0 pa-0"
    >
      <v-col
          cols="10"
          offset="1"
      >
        <v-card height="90vh">
          <iframe oncontextmenu="return false;" id="myFrame" width="100%" height="100%" :src="activeRegion.board"
                  frameborder="0" allowFullScreen="true" style="height: 93vh"></iframe>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <UserPdfReports v-if="!activeRegion.board"></UserPdfReports>
  <adminSearchM v-if="showAdmin && selectAdmin === 'AdminSearchM'"></adminSearchM>
  <AdminManageRegions v-if="showAdmin && selectAdmin === 'AdminManageRegions'"></AdminManageRegions>
  <AdminTopics v-if="showAdmin && selectAdmin === 'AdminTopics'"></AdminTopics>
  <AdminRef v-if="showAdmin && selectAdmin === 'AdminRef'"></AdminRef>
  <AdminAddBestMsgs v-if="showAdmin && selectAdmin === 'AdminAddBestMsgs'"></AdminAddBestMsgs>
  <AdminBestMsgsTinder v-if="showAdmin && selectAdmin === 'AdminBestMsgsTinder'"></AdminBestMsgsTinder>
  <admin-checker v-if="showAdmin && selectAdmin === 'AdminChecker'"></admin-checker>
  <adminNER v-if="showAdmin && selectAdmin === 'AdminNER'"></adminNER>
  <adminTinder v-if="showAdmin && selectAdmin === 'AdminTinder'"></adminTinder>
  <markup-admin v-if="showAdmin && selectAdmin === 'MarkupAdmin'"></markup-admin>
  <markup-theme-select v-if="showAdmin && selectAdmin === 'MarkupThemeSelect'"></markup-theme-select>
  <markup-theme-tinder v-if="showAdmin && selectAdmin === 'MarkupThemeTinder'"></markup-theme-tinder>
  <markup-theme-tinder-single v-if="showAdmin && selectAdmin === 'MarkupThemeTinderSingle'"></markup-theme-tinder-single>
  <markup-emotion-select v-if="showAdmin && selectAdmin === 'MarkupEmotionSelect'"></markup-emotion-select>
  <markup-sentiment-select v-if="showAdmin && selectAdmin === 'MarkupSentimentSelect'"></markup-sentiment-select>
  <admin-emotions-tinder v-if="showAdmin && selectAdmin === 'EmotionTinder'"></admin-emotions-tinder>
</template>

<script>
import {defineComponent} from 'vue';
import AdminSearchM from "@/components/AdminSearchM";
import AdminManageRegions from "@/components/AdminManageRegions";
import AdminTopics from "@/components/AdminTopics";
import AdminRef from "@/components/AdminRef";
import AdminAddBestMsgs from "@/components/AdminAddBestMsgs";
import AdminBestMsgsTinder from "@/components/AdminBestMsgsTinder";
import UserPdfReports from "@/components/UserPdfReports";
import adminChecker from "@/components/AdminChecker";
import adminNER from "@/components/AdminNER";
import adminTinder from "@/components/AdminTinder";
import axios from "axios";
import MarkupAdmin from "@/components/MarkupAdmin/MarkupAdmin.vue";
import MarkupThemeSelect from "@/components/Markup/Theme/Select.vue";
import MarkupThemeTinder from "@/components/Markup/Theme/Tinder.vue";
import MarkupThemeTinderSingle from "@/components/Markup/Theme/Tinder.vue";
import MarkupEmotionSelect from "@/components/Markup/Emotion/Select.vue";
import adminEmotionsTinder from "@/components/AdminEmotionsTinder";
import MarkupSentimentSelect from "@/components/Markup/Sentiment/Select.vue";

let gitStatus = process.env.VUE_APP_GIT_STATUS
let buildStatus = process.env.VUE_APP_BUILD_STATUS
let apiUrl = process.env.VUE_APP_API_LINK

export default defineComponent({
  name: 'HomeView',
  components: {
      MarkupAdmin,
      MarkupThemeSelect,
      MarkupThemeTinder,
      MarkupThemeTinderSingle,
    adminEmotionsTinder,
    MarkupEmotionSelect,
    MarkupSentimentSelect,
      AdminSearchM, AdminManageRegions, AdminTopics, AdminRef,AdminAddBestMsgs,AdminBestMsgsTinder,UserPdfReports, adminChecker, adminNER, adminTinder},
  data() {
    return {
      regionsList: this.$store.state.regionsList,
      editor: this.$store.state.editor,
      activeRegion: {},
      activeAdmin: {},
      selectRegion: "",
      selectAdmin: "",
      showAdmin: true,
      ip: ''
    }
  },
  methods: {
    logout: function () {
      this.$store
          .dispatch("logout")
          .then(data => {
            this.$router.push("/Login")
            console.log(data)
            this.regionsList = []
          })
          .catch(err => console.log(err));
    },
    saveLogs: function (user, action) {
      axios.get(apiUrl + '/logs', {
        params: {
          user: user,
          dateTime: Date.now(),
          domain: window.location.host,
          action: action,
          ip: this.ip,
          userAgent: navigator.userAgent
        }
      }).then(response => {
        console.log(response.data)
      }).catch(error => {
        console.error(error)
      })
    }
  },
  watch: {
    selectRegion(newVal) {
      if(newVal) {
        this.showAdmin = false
        this.selectAdmin = ""
        let regArr = this.regionsList
        let reg = regArr.find(obj => {
          return obj.id === newVal
        })
        this.activeRegion = reg
        this.saveLogs(this.$store.state.user, "user-changeView-" + this.activeRegion.name)
      }
    },
    selectAdmin(newVal) {
      if(newVal) {
        this.showAdmin = true
        this.activeAdmin = newVal
        this.saveLogs(this.$store.state.user, "admin-changeView-" + this.selectAdmin)
        this.selectRegion = ""
        console.log("build: " + buildStatus)
        console.log("git: " + gitStatus)
      }
    }
  },
  mounted() {
    axios.get('https://ipinfo.io/json').then(response => {
      this.ip = response.data.ip
    })
    this.$store
        .dispatch("updateRegions")
        .then((data) => {
          this.regionsList = data.regionsList
          this.editor = data.editor
          this.activeRegion = data.regionsList[0]
          this.selectRegion = this.activeRegion.id
        })
        .catch(err => {
          this.logout()
          console.log(err)
        });
  }
});
</script>
