import {createStore} from 'vuex'
import axios from 'axios'

let apiUrl = process.env.VUE_APP_API_LINK

export default createStore({
    state: {
        status: '',
        token: localStorage.getItem('token') || '',
        regionsList: localStorage.getItem('regionsList') || '',
        editor: localStorage.getItem('editor') || '',
        user: localStorage.getItem('user') || '',
    },
    getters: {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status
    },
    mutations: {
        auth_request(state) {
            state.status = 'loading'
        },
        auth_success(state, token, user) {
            state.status = 'success'
            state.token = token
            state.user = user
        },
        auth_error(state) {
            state.status = 'error'
        },
        updateUser(state, user) {
            state.user = user
        },
        logout(state) {
            state.status = ''
            state.token = ''
        },
    },
    actions: {
        login({commit}, user) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                axios({url: apiUrl + '/signin', data: user, method: 'POST'})
                    .then(resp => {
                        const token = resp.data.token
                        const user = resp.data.user
                        const regionsList = resp.data.regionsList
                        if (resp.data.editor.length > 0) {
                            const editor = resp.data.editor
                            localStorage.setItem('editor', JSON.stringify(editor))
                        }
                        localStorage.setItem('regionsList', JSON.stringify(regionsList))
                        localStorage.setItem('token', token)
                        localStorage.setItem('user', user.email)
                        this.state.user = user.email
                        // Add the following line:
                        axios.defaults.headers.common['Authorization'] = token
                        commit('auth_success', token, user.email)
                        resolve(resp)
                    })
                    .catch(err => {
                        commit('auth_error')
                        localStorage.removeItem('token')
                        reject(err)
                    })
            })
        },
        loginCert({commit}, cert) {
          return new Promise((resolve, reject) => {
              commit('auth_request')
              axios({url: apiUrl + '/checkCert', data: cert, method: 'POST'})
                  .then(resp => {
                      const token = resp.data.token
                      const user = resp.data.user
                      const regionsList = resp.data.regionsList
                      if (resp.data.editor.length > 0) {
                          const editor = resp.data.editor
                          localStorage.setItem('editor', JSON.stringify(editor))
                      }
                      localStorage.setItem('regionsList', JSON.stringify(regionsList))
                      localStorage.setItem('token', token)
                      localStorage.setItem('user', user.email)
                      this.state.user = user.email
                      // Add the following line:
                      axios.defaults.headers.common['Authorization'] = token
                      commit('auth_success', token, user.email)
                      resolve(resp)
                  })
                  .catch(err => {
                      commit('auth_error')
                      localStorage.removeItem('token')
                      reject(err)
                  })
          })
        },
        register({commit}, user) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                axios({url: apiUrl + '/signup', data: user, method: 'POST'})
                    .then(resp => {
                        const token = resp.data.token
                        const user = resp.data.user
                        localStorage.setItem('token', token)
                        localStorage.setItem('user', user.email)
                        this.state.user = user.email
                        // Add the following line:
                        axios.defaults.headers.common['Authorization'] = token
                        commit('auth_success', token, user.email)
                        resolve(resp)
                    })
                    .catch(err => {
                        commit('auth_error', err)
                        localStorage.removeItem('token')
                        reject(err)
                    })
            })
        },
        logout({commit}) {
            return new Promise((resolve) => {
                commit('logout')
                localStorage.removeItem('token')
                localStorage.removeItem('regionsList')
                localStorage.removeItem('editor')
                localStorage.removeItem('user')
                delete axios.defaults.headers.common['Authorization']
                resolve()
            })
        },
        updateRegions({commit}) {
            this.state.user = localStorage.getItem('user')
            commit('updateUser', localStorage.getItem('user'))
            return new Promise((resolve, reject) => {
                axios.get(apiUrl + '/protected', { headers: {"Authorization" : `Bearer ${this.state.token}`} })
                    .then(resp => {
                        localStorage.setItem('regionsList', JSON.stringify(resp.data.regionsList))
                        if (resp.data.editor.length > 0) {
                            const editor = resp.data.editor
                            localStorage.setItem('editor', JSON.stringify(editor))
                        }
                        resolve({regionsList: resp.data.regionsList, editor: resp.data.editor})
                    })
                    .catch(err => {
                        localStorage.removeItem('regionList')
                        localStorage.removeItem('token')
                        localStorage.removeItem('editor')
                        localStorage.removeItem('user')
                        delete axios.defaults.headers.common['Authorization']
                        reject(err)
                    })
            })
        }
    },
    modules: {}
})
