<script>
export default {
    name: "AlertsList",
    data: () => ({
        alerts: [],
    }),
    methods: {
        addAlert: function(type, title, text){
            this.alerts.push({
                type: type,
                title: title,
                text: text,
                status: true
            })
        },
        removeAlert: function (alert){
            this.alerts = this.alerts.filter(a => a !== alert);
        }
    }
}
</script>

<template>
    <v-container fluid class="pa-0 " style="position: absolute; top: 80px; z-index: 1000;width: 100%">
        <v-row class="ma-0 pa-0 justify-center"
               v-for="alert in alerts"
               :key="alert"
        >
            <v-col class="v-col-12 v-col-md-6 v-col-lg-4">
                <v-alert border="top" :type="alert.type" closable v-model="alert.status"
                         @update:model-value="value => removeAlert(alert)">
                    <v-alert-title>
                        {{ alert.title }}
                    </v-alert-title>
                    {{alert.text}}
                </v-alert>
            </v-col>
        </v-row>
    </v-container>
</template>

<style scoped>

</style>