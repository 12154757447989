<script>
export default {
    name: "MarkupPartsInfo",
    props: [
        'text',
    ],
    data: () => {
        return {
            isOpened: false
        }
    }
}
</script>

<template>
    <v-icon
        v-show="text && text.length > 0"
        class="right-top text-medium-emphasis"
        v-on:click="this.isOpened = true"
        icon="mdi-information"></v-icon>
    <v-dialog v-model="isOpened" width="auto" min-width="300px">
        <v-icon
            class="right-top"
            style="z-index: 2"
            v-on:click="this.isOpened = false"
            icon="mdi-close"></v-icon>
        <v-card>
            <v-card-title>
                Инструкция по разметке
            </v-card-title>
            <v-card-text class="break-space" v-html="text">
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<style scoped>
.right-top {
    position: absolute;
    top: 10px;
    right: 5px;
}

.break-space {
    white-space: break-spaces;
}
</style>