<template>
  <v-container fluid class="pa-0" style="position: absolute; top: 80px" id="app">
    <v-row
        class="ma-0 pa-0"
    >
      <v-progress-circular
          :size="150"
          :width="15"
          color="blue"
          indeterminate
          style="left: 47vw; top: 35vh; z-index: 1000; position: absolute;"
          v-if="distsWithReports.length < 1"
      ></v-progress-circular>
      <v-col
          :cols="sizes[showSize].districtColoumnSize"
          offset="1"
      >
        <v-row>
          <v-col>
            <v-btn block v-on:click="viewType = 'map'"><v-icon>mdi-map</v-icon>На карте</v-btn>
          </v-col>
          <v-col>
            <v-btn block v-on:click="viewType = 'list'"><v-icon>mdi-apps</v-icon>Списком</v-btn>
          </v-col>
        </v-row>
        <v-col>
          <v-row>
          <v-col cols="9" >
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  :viewBox="sizes[showSize].mapSize"
                  id="svg2"
                  v-if="viewType === 'map'"
              >
                <g
                    id="g3831"
                    style="fill:#fefee9;fill-opacity:1;fill-rule:evenodd;stroke:#FFFFFF;stroke-opacity:1;stroke-linejoin:round;stroke-linecap:round;stroke-width:0.8;stroke-miterlimit:4;stroke-dasharray:none">
                  <path v-for="district in districts" xmlns="http://www.w3.org/2000/svg" :d="district.location" :id="district.id"  :style="'fill:' + district.color" :name="district.name" :key="district.id" v-tooltip="district.name"/>
                  <path v-for="district in distsWithReports" xmlns="http://www.w3.org/2000/svg" :d="district.location" :id="district.id"  style="fill:#153BFE" :name="district.name" :key="district.id" v-on:click="selectDistrict(district)" v-tooltip="district.name" class="activeDist"/>
                  <path v-if="selectedDistict.name" xmlns="http://www.w3.org/2000/svg" :d="selectedDistict.location" :id="selectedDistict.id"  style="fill:#153BFE" opacity="100%" :name="selectedDistict.name" v-tooltip="selectedDistict.name"/>
                </g>
              </svg>
          </v-col>
          <v-col v-if="viewType === 'map'">
            <v-card class="pa-2 ma-2" width="350" v-if="selectedDistict.name">
              <v-row align="center" hide-gutters no-gutters>
                <v-col
                    class="text-h6"
                    cols="8"
                >
                  {{ selectedDistict.name }}
                </v-col>
                <v-col cols="4" class="text-right">
                  <v-avatar
                      class="ma-3"
                      size="75"
                      rounded="0"
                  >
                    <v-img :src="selectedDistict.logo"></v-img>
                  </v-avatar>
                </v-col>
              </v-row>
              <v-list density="compact">
                <v-list-subheader>ОТЧЕТЫ</v-list-subheader>
                <v-list-item
                    active-color="primary"
                    v-on:click="reportSelected=report.reportLink" v-for="report in reports[selectedDistict.id]" :key="report.id"
                >
                  <template v-slot:prepend>
                    <v-icon icon="mdi-file-chart"></v-icon>
                  </template>
                  <v-list-item-title>{{ report.reportName }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          </v-row>
        </v-col>
        <div v-if="viewType === 'list'" class="d-flex flex-wrap justify-space-around">
            <v-card class="pa-2 ma-2" width="350">
              <v-row align="center" hide-gutters no-gutters>
                <v-col
                    class="text-h6"
                    cols="8"
                >
                  Все районы Самарской области
                </v-col>
                <v-col cols="4" class="text-right">
                  <v-avatar
                      class="ma-3"
                      size="75"
                      rounded="0"
                  >
                    <v-img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Coat_of_arms_of_Samara_Oblast.svg/150px-Coat_of_arms_of_Samara_Oblast.svg.png"></v-img>
                  </v-avatar>
                </v-col>
              </v-row>
              <v-list density="compact">
                <v-list-subheader>ОТЧЕТЫ</v-list-subheader>
                <v-list-item
                    active-color="primary"
                    v-on:click="downloadAllS"
                >
                  <template v-slot:prepend>
                    <v-icon icon="mdi-file-chart"></v-icon>
                  </template>
                  <v-list-item-title>Сентябрь 2022</v-list-item-title>
                </v-list-item>
                <v-list-item
                    active-color="primary"
                    v-on:click="downloadAllO"
                >
                  <template v-slot:prepend>
                    <v-icon icon="mdi-file-chart"></v-icon>
                  </template>
                  <v-list-item-title>Октябрь 2022</v-list-item-title>
                </v-list-item>
                <v-list-item
                    active-color="primary"
                    v-on:click="downloadAll1"
                >
                  <template v-slot:prepend>
                    <v-icon icon="mdi-file-chart"></v-icon>
                  </template>
                  <v-list-item-title>Ноябрь 2022</v-list-item-title>
                </v-list-item>
                <v-list-item
                    active-color="primary"
                    v-on:click="downloadAll2"
                >
                  <template v-slot:prepend>
                    <v-icon icon="mdi-file-chart"></v-icon>
                  </template>
                  <v-list-item-title>Декабрь 2022</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
            <v-card class="pa-2 ma-2" width="350" v-for="district in distsWithReports" :key="district.id">
              <v-row align="center" hide-gutters no-gutters>
                <v-col
                    class="text-h6"
                    cols="8"
                >
                  {{ district.name }}
                </v-col>
                <v-col cols="4" class="text-right">
                  <v-avatar
                      class="ma-3"
                      size="75"
                      rounded="0"
                  >
                    <v-img :src="district.logo"></v-img>
                  </v-avatar>
                </v-col>
              </v-row>
              <v-list density="compact">
                <v-list-subheader>ОТЧЕТЫ</v-list-subheader>
                <v-list-item
                    active-color="primary"
                    v-on:click="reportSelected=report.reportLink" v-for="report in reports[district.id]" :key="report.id"
                >
                  <template v-slot:prepend>
                    <v-icon icon="mdi-file-chart"></v-icon>
                  </template>
                  <v-list-item-title>{{ report.reportName }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </div>
      </v-col>
      <v-col
          cols="6"
          v-if="reportSelected"
      >
        <object :data="reportSelected" type="application/pdf" style="width: 100%; height: max(90vh, 100%);">
          <iframe :src="'https://docs.google.com/viewer?url=' + reportSelected + '&embedded=true'"></iframe>
        </object>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
let apiUrl = process.env.VUE_APP_API_LINK

export default {
  name: "UserPdfReports",
  data () {
    return {
      viewType: 'list',
      selectedDistict: {},
      reportSelected: '',
      sizes: {
        big: {
          mapSize: '-450 0 1700 1700',
          districtColoumnSize: 10
        },
        small: {
          mapSize: '0 0 1000 1000',
          districtColoumnSize: 5
        }
      },
      showSize: 'big',
      districts: [],
      reports: []
    }
  },
  methods: {
    downloadAllS: function () {
      window.open("https://storage.yandexcloud.net/odas/dist_reports/september/%D0%A1%D0%B0%D0%BC%D0%B0%D1%80%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C.zip","_self")
    },
    downloadAllO: function () {
      window.open("https://storage.yandexcloud.net/odas/dist_reports/october/%D0%A1%D0%B0%D0%BC%D0%B0%D1%80%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C.zip","_self")
    },
    downloadAll1: function () {
      window.open("https://storage.yandexcloud.net/odas/dist_reports/november/%D0%A1%D0%B0%D0%BC%D0%B0%D1%80%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C.zip","_self")
    },
    downloadAll2: function () {
      window.open("https://storage.yandexcloud.net/odas/dist_reports/december/%D0%A1%D0%B0%D0%BC%D0%B0%D1%80%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C.zip","_self")
    },
    selectDistrict: function (district) {
      this.selectedDistict = district
      console.log(district)
    },
    getDists: function () {
      axios.get(apiUrl + "/getDists", {headers: {"Authorization": `Bearer ${this.$store.state.token}`}})
          .then(resp => {
            this.districts = resp.data
          })
    },
    getUserReports: function () {
      axios.get(apiUrl + "/getUserReports?user=" + this.$store.state.user, {headers: {"Authorization": `Bearer ${this.$store.state.token}`}})
          .then(resp => {
            let data = resp.data
            let dataObj = this.groupBy(data, 'dist')
            this.reports = dataObj
          })
    },
    groupBy: function(xs, key) {
      return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    }
  },
  watch: {
    reportSelected: {
      handler() {
        this.showSize = 'small'
      },
      deep: true
    }
  },
  mounted() {
    this.getDists()
    this.getUserReports()
    setTimeout(() => {
        this.getDists()
        this.getUserReports()
    }, 3000)
  },
  computed: {
    distsWithReports: function () {
      return this.districts.filter(i => this.reports[i.id])
    }
  }
}
</script>

<style scoped>
.activeDist{
  opacity: 50%;
}
.activeDist:hover{
  opacity: 100%;
}
</style>