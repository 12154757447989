<template>
  <v-container fluid class="pa-0" style="position: absolute; top: 80px" id="app">
    <v-row
        class="ma-0 pa-0"
    >
      <v-col
          cols="10"
          offset="1"
      >
        <v-alert :type="alertType" class="elevation-5" v-if="alertText">
          Сообщение размеченно как {{ alertText }}
        </v-alert>
        <v-card class="mt-3">
          <h3 class="pa-3" v-if="!loading">
            {{ msgs[msgIndex].text }}
          </h3>
          <v-progress-circular
              v-else
              :size="50"
              color="primary"
              indeterminate
          ></v-progress-circular>
          <v-divider></v-divider>
          <v-row justify="space-between" class="pt-6 mx-12 mb-3">
            <v-slider
                v-model="msgs[msgIndex].positive"
                thumb-label="always"
            >
              <template v-slot:thumb-label="{ modelValue }">
                {{ satisfactionEmojis[Math.min(Math.floor(modelValue / 10), 9)] }}
              </template>
            </v-slider>
            <v-btn block color="info" v-on:click="setTag(msgs[msgIndex].id, msgs[msgIndex].positive)">Сохранить</v-btn>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'

let apiUrl = process.env.VUE_APP_API_LINK

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array
}

export default {
  el: "#app",
  name: "AdminCheckPositive",
  data: () => ({
    msgs: [{text: "Идет загрузка..."}],
    msgId: 0,
    msgIndex: 0,
    loading: false,
    lastIndex: 0,
    alertText: '',
    alertType: '',
    satisfactionEmojis: ['😭', '😢', '☹️', '🙁', '😐', '🙂', '😊', '😁', '😄', '😍'],
  }),
  directives: {
    focus: {
      inserted(el) {
        el.focus()
      }
    }
  },
  methods: {
    getStat: function () {
      axios.get(apiUrl + "/getTinderStat", {headers: {"Authorization": `Bearer ${this.$store.state.token}`}})
          .then(resp => {
            console.log(resp.data[0])
          })
    },
    getMsgs: function () {
      this.loadingState = true
      this.topics = []
      axios.get(apiUrl + "/getBestMsgs", {headers: {"Authorization": `Bearer ${this.$store.state.token}`}})
          .then(resp => {
            this.msgs = shuffleArray(resp.data)
            this.msgIndex = this.getMsgIndex()
            this.msgId = this.getMsgId().id
          })
    },
    setTag: function (id, tag) {
      this.loading = true
      axios({
        url: apiUrl + '/addTagForBestMsg',
        headers: {"Authorization": `Bearer ${this.$store.state.token}`},
        method: 'POST',
        data: {
          user: this.$store.state.user,
          tag: tag,
          id: id,
        }
      })
          .then(resp => {
            this.lastIndex = this.msgIndex
            this.loading = false
            this.msgs[this.msgIndex][this.$store.state.user] = tag
            this.msgIndex = this.getMsgIndex()
            this.msgId = this.getMsgId().id
            console.log(resp)
          })
          .catch(err => {
            console.log(err)
          })
    },
    getMsgIndex: function () {
      let arr = this.msgs
      let user = this.$store.state.user
      // console.log(arr.findIndex(x => x[user] == null))
      return arr.findIndex(x => x[user] == null)
    },
    getMsgId: function () {
      let arr = this.msgs
      let user = this.$store.state.user
      return arr.find(x => x[user] == null)
    }
  },
  mounted() {
    this.getMsgs()
    window.addEventListener("keyup", e => {
      if (e.type === "keyup") {
        if (e.key === "w" || e.key === "ц") {
          this.setTag(this.msgId, 999)
          this.alertText = " - ПРОПУСК"
          this.alertType = "info"
        } else if (e.key === "a" || e.key === "ф") {
          this.setTag(this.msgId, -1)
          this.alertText = " - НЕВЕРНО"
          this.alertType = "error"
        } else if (e.key === "d" || e.key === "в") {
          this.setTag(this.msgId, 1)
          this.alertText = " - ПРАВИЛЬНО"
          this.alertType = "success"
        }
      }
    });
  },
  watch: {}
}
</script>

<style scoped>

</style>