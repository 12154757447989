<template>
  <v-container fluid class="pa-0" style="position: absolute; top: 80px">
    <v-row
        class="ma-0 pa-0"
    >
      <v-col
          cols="10"
          offset="1"
      >
        <v-card class="pa-3 mt-3" min-width="380">
          <p class="v-card-title mb-6">Статистика пользователей</p>
          <v-sheet
              class="d-flex flex-wrap"
          >
            <v-sheet
                v-for="(value, name) in stat" :key="name"
                class="ma-2 pa-2 elevation-3"
            >
              {{ name }}<v-slider
                v-model="stat[name]"
                min="0"
                max="10000"
                step="1"
                thumb-label="always"
                readonly
                style="min-width: 250px!important"
            ></v-slider>
            </v-sheet>
          </v-sheet>
        </v-card>
        <v-card class="mt-3">
          <h3 class="pa-3" v-if="!loading">
            {{ msgs[msgIndex].text }}
          </h3>
          <v-progress-circular
              v-else
              :size="50"
              color="primary"
              indeterminate
          ></v-progress-circular>
          <v-divider v-if="selectedThemes.length > 0"></v-divider>
          <v-row v-if="selectedThemes.length > 0">
            <v-col>
              <v-chip
                  class="ma-2"
                  variant="outlined"
                  v-for="theme in selectedThemes"
                  :key="theme"
                  v-tooltip="theme.SPHERE"
              >
                {{ theme.THEME }}
                <v-icon start icon="mdi-close" class="pl-5" v-on:click="deselectTheme(theme)"></v-icon>
              </v-chip>
            </v-col>
            <v-col cols="2" class="ma-3">
              <v-btn block color="info" v-on:click="setTags(msgs[msgIndex].id, selectedThemes)">Сохранить</v-btn>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-card
              class="d-flex flex-wrap"
              flat
              tile
          >
            <v-btn v-for="sphere in spheres" :key="sphere" class="pa-2 ma-2" v-on:click="selectedSphere = sphere">{{ sphere[0].SPHERE }}</v-btn>
          </v-card>
          <v-divider></v-divider>
          <v-card
              class="d-flex flex-wrap"
              flat
              tile
          >
            <v-btn variant="outlined" v-for="theme in selectedSphere" :key="theme" class="pa-2 ma-2" v-on:click="selectTheme(theme)">{{ theme.THEME }}</v-btn>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import spheresThemes from '../assets/spheresThemes.json'
let apiUrl = process.env.VUE_APP_API_LINK

export default {
  name: "AdminRef",
  data: () => ({
    stat: {},
    msgs: [{text: "Идет загрузка..."}],
    spheres: [],
    selectedThemes: [],
    selectedSphere: "",
    msgIndex: 0,
    loading: false
  }),
  methods: {
    getMsgs: function () {
      this.loadingState = true
      this.topics = []
      axios.get(apiUrl + "/getRefMsgs", {headers: {"Authorization": `Bearer ${this.$store.state.token}`}})
          .then(resp => {
            this.msgs = resp.data
            this.msgIndex = this.getMsgIndex()
            // console.log(this.getMsgIndex())
          })
    },
    getStat: function () {
      this.loadingState = true
      this.topics = []
      axios.get(apiUrl + "/getRefStat", {headers: {"Authorization": `Bearer ${this.$store.state.token}`}})
          .then(resp => {
            this.stat = resp.data
          })
    },
    setTags: function (id, tags) {
      this.loading = true
      var spheres = []
      var themes = []
      for (let tag in tags) {
        if(!spheres.includes(tags[tag].SPHERE)){
          spheres.push(tags[tag].SPHERE)
        }
        if(!themes.includes(tags[tag].LABEL)){
          themes.push(tags[tag].LABEL)
        }
      }
      let json = JSON.stringify({spheres:spheres,themes:themes}).replaceAll('"', "'")
      axios({
        url: apiUrl + '/addTagForRef',
        headers: {"Authorization": `Bearer ${this.$store.state.token}`},
        method: 'POST',
        data: {
          user: this.$store.state.user,
          tag: json,
          id: id,
        }
      })
          .then(resp => {
            this.loading = false
            this.msgs[this.msgIndex][this.$store.state.user] = json
            this.getStat()
            this.msgIndex = this.getMsgIndex()
            this.selectedThemes = []
            this.selectedSphere = {}
            // console.log(this.getMsgIndex())
            console.log(resp)
          })
          .catch(err => {
            console.log(err)
          })
    },
    getMsgIndex: function () {
      let arr = this.msgs
      let user = this.$store.state.user
      return arr.findIndex(x => x[user] == null)
    },
    groupBy: function(xs, key) {
      return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    selectTheme: function (theme) {
      if (!this.selectedThemes.includes(theme)) {
        this.selectedThemes.push(theme)
      }
    },
    deselectTheme: function (theme) {
      const index = this.selectedThemes.indexOf(theme);
      if (index > -1) { // only splice array when item is found
        this.selectedThemes.splice(index, 1); // 2nd parameter means remove one item only
      }
    },
  },
  mounted() {
    this.getMsgs()
    this.getStat()
    this.spheres = this.groupBy(spheresThemes, 'SPHERE')
  },
  watch: {
  }
}
</script>

<style scoped>

</style>