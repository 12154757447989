import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import {loadFonts} from './plugins/webfontloader'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

loadFonts()


createApp(App)
    .use(router)
    .use(store)
    .use(vuetify)
    .use(FloatingVue)
    .mount('#app')
