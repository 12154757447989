<script>

import AlertsList from "@/components/AlertsList.vue";
import MarkupAdminStatistics from "@/components/MarkupAdmin/Statistics.vue";
import MarkupAdminData from "@/components/MarkupAdmin/Data.vue";
import MarkupAdminControls from "@/components/MarkupAdmin/Controls.vue"

export default {
    name: "MarkupAdmin",
    components: {AlertsList, MarkupAdminStatistics, MarkupAdminData, MarkupAdminControls},
    data: () => ({
        current_tab: null,
        tabs: ["Статистика", "Данные", "Управление"]
    }),
    methods: {
        onAlert: function(type, title, text) {
            this.$refs.alerts.addAlert(type, title, text);
        }
    }
}
</script>

<template>
<alerts-list ref="alerts"></alerts-list>
<v-container fluid class="pa-0" style="position: absolute; top: 80px">
    <v-row>
        <v-col cols="10" offset="1">
            <v-tabs v-model="current_tab" grow>
                <v-tab v-for="tab in tabs" :key="tab">{{tab}}</v-tab>
            </v-tabs>
            <v-window v-model="current_tab">
                <v-window-item key="Статистика">
                    <markup-admin-statistics @alert="onAlert"></markup-admin-statistics>
                </v-window-item>
                <v-window-item key="Данные">
                    <markup-admin-data @alert="onAlert"></markup-admin-data>
                </v-window-item>
                <v-window-item key="Управление">
                    <markup-admin-controls @alert="onAlert"></markup-admin-controls>
                </v-window-item>
            </v-window>
        </v-col>
    </v-row>
</v-container>
</template>

<style scoped>

</style>