<template>
  <v-container fluid class="pa-0" style="position: absolute; top: 80px">
    <v-row
        class="ma-0 pa-0"
    >
      <v-col
          cols="10"
          offset="1"
      >
        <v-card class="pa-3 mt-3" min-width="380">
          <p class="v-card-title mb-6">Статистика пользователей</p>
          <v-sheet
              class="d-flex flex-wrap"
          >
            <v-sheet
                v-for="user in stat" :key="user.user"
                class="ma-2 pa-2 elevation-3"
            >
              {{ user.user }}
              <v-slider
                  v-model="user.count"
                  min="0"
                  max="500"
                  step="1"
                  thumb-label="always"
                  readonly
                  style="min-width: 250px!important"
              ></v-slider>
            </v-sheet>
          </v-sheet>
        </v-card>
        <v-card class="mt-3">
          <h3 class="pa-3" v-if="!loading">
            {{ msgs[msgIndex].text }}
          </h3>
          <v-progress-circular
              v-else
              :size="50"
              color="primary"
              indeterminate
          ></v-progress-circular>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="2" class="ma-3" v-if="msgIndex > 0">
              <v-btn block color="info" v-on:click="moveBack()">Назад</v-btn>
            </v-col>
            <v-col>
              <v-chip
                  class="ma-2"
                  variant="outlined"
              >
                {{ msgs[msgIndex].emotion }}
              </v-chip>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-card
              class="d-flex flex-wrap"
              flat
              tile
          >
            <v-row align="center" justify="center" class="pa-3">
              <v-col cols="auto">
                <v-btn density="default" icon="mdi-thumb-down" color="red" v-on:click="setTags(msgs[msgIndex].id, false)"></v-btn>
              </v-col>

              <v-col cols="auto">
                <v-btn density="default" icon="mdi-thumb-up" color="green" v-on:click="setTags(msgs[msgIndex].id, true)"></v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'

let apiUrl = process.env.VUE_APP_API_LINK

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array
}

export default {
  name: "AdminRef",
  data: () => ({
    stat: {},
    msgs: [{text: "Идет загрузка..."}],
    spheres: [],
    msgIndex: 0,
    loading: false,
  }),
  methods: {
    findByT: function(array, LABEL_T) {
      var result;
      array.some(o => result = o.LABEL_T === LABEL_T ? o : find(o.children || [], LABEL_T));
      return result;
    },
    getMsgs: function () {
      this.loadingState = true
      this.topics = []
      axios.get(apiUrl + "/emotions/getTinderMsgs", {headers: {"Authorization": `Bearer ${this.$store.state.token}`}})
          .then(resp => {
            this.msgs = shuffleArray(resp.data);
            this.msgIndex = 0
            // console.log(this.getMsgIndex())
          })
    },
    getStat: function () {
      this.loadingState = true
      this.topics = []
      axios.get(apiUrl + "/emotions/getTinderStat", {headers: {"Authorization": `Bearer ${this.$store.state.token}`}})
          .then(resp => {
            this.stat = resp.data
          })
    },
    moveBack: function () {
      this.msgIndex = this.msgIndex - 1
    },
    setTags: function (id, result) {
      console.log(id + result)
      axios.post(apiUrl + "/emotions/saveTinderMsgs", {
            result: result,
            msgId: id,
        }, {headers: {"Authorization": `Bearer ${this.$store.state.token}`}})
      .then(resp => {
        console.log(resp)
        this.msgIndex = this.msgIndex + 1
        this.getStat()
      })
    }
  },
  mounted() {
    this.getMsgs()
    this.getStat()
  },
  watch: {}
}
</script>

<style scoped>

</style>