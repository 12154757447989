<template>
    <alerts-list ref="alerts"></alerts-list>
    <v-container fluid class="pa-0" style="position: absolute; top: 80px">
        <v-row class="ma-0 pa-0">
            <v-col cols="10" offset="1">
                <markup-parts-statistics ref="statistics" :markup_type="markup_type" :error_alert="errorAlert"/>
                <markup-parts-body :is_loaded="is_loaded" :message="message" :instruction="instruction">
                    <template v-slot:markup>
                        <v-divider></v-divider>
                        <v-row>
                            <v-col><h4>Выбранные темы</h4></v-col>
                        </v-row>
                        <v-row id="selected-themes" class="ma-1">
                            <v-col>
                                <v-chip
                                    v-for="theme_id in markup"
                                    :key="theme_id"
                                    class="ma-1" variant="outlined"
                                    :color="themeColor(theme_id)"
                                    v-on:click="switchTheme(theme_id)"
                                >
                                    {{ themes_list.get(theme_id).sphere_name }}
                                    - {{ themes_list.get(theme_id).theme_name }}
                                </v-chip>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                            <v-col><h4>Потерянные темы</h4></v-col>
                        </v-row>
                        <v-row id="deselected-themes" class="ma-1">
                            <v-col>
                                <v-chip
                                    class="ma-1" variant="outlined"
                                    :color="themeColor(theme_id)"
                                    v-on:click="switchTheme(theme_id)"
                                    v-for="theme_id in this.message.condition.themes.filter((tid) => !markup.includes(tid))"
                                    :key="theme_id"
                                >
                                    {{ themes_list.get(theme_id).sphere_name }}
                                    - {{ themes_list.get(theme_id).theme_name }}
                                </v-chip>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:additional-controls>
                        <markup-controls-theme-selector
                            :spheres="spheres_list" :themes="themes_list"
                            :theme-color="themeColor" :select-theme="switchTheme"
                        />
                    </template>
                    <template v-slot:controls>
                        <v-row>
                            <v-col>
                                <v-btn
                                    color="info"
                                    v-on:click="moveBack()"
                                    :disabled="previous_markup_id == null"
                                >
                                    Назад
                                </v-btn>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col>
                                <v-btn density="default" color="green" class="float-right"
                                       v-on:click="setMarkup(markup)"
                                >
                                    Сохранить
                                </v-btn>
                            </v-col>
                        </v-row>
                    </template>
                </markup-parts-body>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import MarkupThemeBase from "@/components/Markup/Theme/Base.vue";
import AlertsList from "@/components/AlertsList.vue";
import MarkupPartsStatistics from "@/components/Markup/Parts/Statistics.vue";
import MarkupPartsBody from "@/components/Markup/Parts/Body.vue";
import MarkupControlsThemeSelector from "@/components/Markup/Controls/SphereThemeSelector.vue";


export default {
    name: "MarkupThemeSelect",
    components: {AlertsList, MarkupPartsStatistics, MarkupPartsBody, MarkupControlsThemeSelector},
    extends: MarkupThemeBase,
    data: () => ({
        markup_type: "ThemeSelect",
        current_sphere: ""
    }),
    methods: {
        themeColor: function (theme_id) {
            if (this.markup.includes(theme_id)) {
                if (this.message.condition.themes.includes(theme_id)) {
                    return 'green';
                } else {
                    return 'blue'
                }
            } else {
                if (this.message.condition.themes.includes(theme_id)) {
                    return 'red';
                }
            }
        },
        switchTheme: function (theme_id) {
            if (this.markup === null) {
                this.markup = [];
            }
            if (this.markup.includes(theme_id)) {
                this.markup = this.markup.filter((tid) => tid !== theme_id);
            } else {
                this.markup.push(theme_id);
            }
        },
        defaultMarkup: function () {
            return []
        }
    }
}
</script>