<template>
  <v-container fluid class="pa-0" style="position: absolute; top: 80px">
    <v-row
        class="ma-0 pa-0"
    >
      <v-col
          cols="10"
          offset="1"
      >
        <v-card class="pa-3 mt-3" min-width="380">
          <p class="v-card-title mb-6">Статистика пользователей</p>
          <v-sheet
              class="d-flex flex-wrap"
          >
            <v-sheet
                v-for="user in stat" :key="user.add_by"
                class="ma-2 pa-2 elevation-3"
            >
              {{ user.add_by }}
              <v-slider
                  v-model="user.count"
                  min="0"
                  :max="Math.round((user.count+1000)/1000)*1000"
                  step="1"
                  thumb-label="always"
                  readonly
                  style="min-width: 250px!important"
              ></v-slider>
            </v-sheet>
          </v-sheet>
        </v-card>
        <v-card class="mt-3">
          <h3 class="pa-3" v-if="!loading">
            {{ msgs[msgIndex].text }}
          </h3>
          <v-progress-circular
              v-else
              :size="50"
              color="primary"
              indeterminate
          ></v-progress-circular>
          <v-divider v-if="sT.length > 0"></v-divider>
          <v-row v-if="sT.length > 0">
            <v-col cols="2" class="ma-3" v-if="msgIndex > 0">
              <v-btn block color="info" v-on:click="moveBack()">Назад</v-btn>
            </v-col>
            <v-col>
              <v-chip
                  class="ma-2"
                  variant="outlined"
                  v-for="theme in sT"
                  :key="theme"
              >
                {{ findByT(spheresThemes, theme.toString()).THEME }}
              </v-chip>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-card
              class="d-flex flex-wrap"
              flat
              tile
          >
            <v-row align="center" justify="center" class="pa-3">
              <v-col cols="auto">
                <v-btn density="default" icon="mdi-thumb-down" color="red" v-on:click="setTags(msgs[msgIndex].msg_id, -1)"></v-btn>
              </v-col>

              <v-col cols="auto">
                <v-btn density="default" icon="mdi-thumb-up" color="green" v-on:click="setTags(msgs[msgIndex].msg_id, 1)"></v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
// import spheresThemes from '../assets/spheresThemes.json'
let spheresThemes = []
fetch(
    "https://opensheet.elk.sh/1LdRmybdE9ATMEqHE5yLUF7NeHwnDYbxbbRwkqnefnpc/directory"
)
    .then((res) => res.json())
    .then((data) => {
      spheresThemes = data
    });

let apiUrl = process.env.VUE_APP_API_LINK

// const findByT = (array, LABEL_T) => {
//   var result;
//   array.some(o => result = o.LABEL_T === LABEL_T ? o : find(o.children || [], LABEL_T));
//   return result;
// };

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array
}

function splitIntoArray(num) {
  return String(num).split(',').map(Number);
}

// function sortByProcessedUserCount(array) {
//   array.sort((a, b) => {
//     let aCount = 0;a.values().forEach(val => {if(val!=null){aCount++}});
//     let bCount = 0;b.values().forEach(val => {if(val!=null){bCount++}});
//     return bCount - aCount;
//   })
//   return array;
// }

export default {
  name: "AdminRef",
  data: () => ({
    stat: {},
    msgs: [{text: "Идет загрузка..."}],
    spheres: [],
    selectedThemes: [],
    selectedSphere: "",
    sT: [],
    sS: [],
    msgIndex: 0,
    loading: false,
    spheresThemes: spheresThemes
  }),
  methods: {
    findByT: function(array, LABEL_T) {
      var result;
      array.some(o => result = o.LABEL_T === LABEL_T ? o : find(o.children || [], LABEL_T));
      return result;
    },
    getMsgs: function () {
      this.loadingState = true
      this.topics = []
      axios.get(apiUrl + "/getCheckMsgsV2", {headers: {"Authorization": `Bearer ${this.$store.state.token}`}})
          .then(resp => {
            this.msgs = shuffleArray(resp.data);
            this.msgIndex = 0
            this.sS = []
            this.sT = []
            this.sS = splitIntoArray(this.msgs[this.msgIndex].spheres)
            this.sT = splitIntoArray(this.msgs[this.msgIndex].themes)
            // console.log(this.getMsgIndex())
          })
    },
    getStat: function () {
      this.loadingState = true
      this.topics = []
      axios.get(apiUrl + "/getStat2", {headers: {"Authorization": `Bearer ${this.$store.state.token}`}})
          .then(resp => {
            this.stat = resp.data
          })
    },
    moveBack: function () {
      this.msgIndex = this.msgIndex - 1
      this.selectedThemes = []
      this.selectedSphere = {}
      this.sS = []
      this.sT = []
      this.sS = splitIntoArray(this.msgs[this.msgIndex].spheres)
      this.sT = splitIntoArray(this.msgs[this.msgIndex].themes)
    },
    setTags: function (id, tags) {
        console.log(id + tags)
        this.msgIndex = this.msgIndex + 1
        this.selectedThemes = []
        this.selectedSphere = {}
        this.sS = []
        this.sT = []
        this.sS = splitIntoArray(this.msgs[this.msgIndex].spheres)
        this.sT = splitIntoArray(this.msgs[this.msgIndex].themes)
    },
    groupBy: function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    selectTheme: function (theme) {
      if (!this.selectedThemes.includes(theme)) {
        this.selectedThemes.push(theme)
      }
      if (!this.sS.includes(+theme['LABEL_S'])) {
        this.sS.push(+theme['LABEL_S'])
      }
      if (!this.sT.includes(+theme['LABEL_T'])) {
        this.sT.push(+theme['LABEL_T'])
      }
    },
    deselectTheme: function (theme) {
      let index = this.sT.indexOf(theme);
      if (index > -1) { // only splice array when item is found
        this.sT.splice(index, 1); // 2nd parameter means remove one item only
      }
    },
  },
  mounted() {
    this.getMsgs()
    this.getStat()
    this.spheres = this.groupBy(spheresThemes, 'SPHERE')
  },
  watch: {}
}
</script>

<style scoped>

</style>