<template>
    <v-card class="pa-3 mt-3">
        <h3>Статистика пользователей</h3>
        <v-row
            v-if="statistics != null"
        >
            <v-col
                v-for="user in statistics" :key="user"
                class="v-col-12 v-col-sm-6 v-col-md-4 v-col-lg-3"
            >
                <v-card class="pl-2 pr-2 pt-1">
                    {{ user.user_id.substring(0, user.user_id.search("@")) }}: {{ user.current }}
                    <v-slider
                        v-model="user.current"
                        thumb-size="0"
                        min="0"
                        :max="user.total"
                        step="1"
                        readonly
                    ></v-slider>
                </v-card>
            </v-col>
        </v-row>
        <v-progress-circular
            v-else
            :size="50"
            color="primary"
            indeterminate
        ></v-progress-circular>
    </v-card>
</template>

<script>
import MarkupApi from "@/plugins/markup_api";

export default {
    name: "MarkupPartsStatistics",
    props: [
        'markup_type',
        'error_alert'
    ],
    data: () => ({
        statistics: null,
    }),
    methods: {
        loadStatistics: function () {
            MarkupApi.get("statistics", this.$store.state.token, {type: this.markup_type})
                .then(resp => {
                    this.statistics = resp.data;
                }).catch(reason => {
                console.log(reason);
                this.error_alert(3794126800);
            });
        },
    },
    mounted() {
        this.loadStatistics();
    },
}
</script>
