<script>
import MarkupApi from "@/plugins/markup_api";
import LineChart from "@/components/MarkupAdmin/LineChart.vue";

let period_format = {
    days: (cnt) => {
        if (cnt === 0) {
            return "";
        }
        if ((cnt % 100) > 4 && (cnt % 100) < 21) {
            return cnt + " дней";
        }

        let last_digit = cnt % 10;
        if (last_digit === 1) {
            return cnt + " день"
        }
        if (last_digit < 5) {
            return cnt + " дня"
        }
        return cnt + " дней";
    },
    hours: (cnt) => {
        if (cnt === 0) {
            return "";
        }
        if (cnt > 4 && cnt < 21) {
            return cnt + " часов";
        }

        let last_digit = cnt % 10;
        if (last_digit === 1) {
            return cnt + " час"
        }
        if (last_digit < 5) {
            return cnt + " часа"
        }
        return cnt + " часов";
    },
    minutes: (cnt) => {
        if (cnt === 0) {
            return "";
        }
        if (cnt > 4 && cnt < 21) {
            return cnt + " минут";
        }

        let last_digit = cnt % 10;
        if (last_digit === 1) {
            return cnt + " минута"
        }
        if (last_digit < 5) {
            return cnt + " минуты"
        }
        return cnt + " минут";
    }
}

let colors = [
    "#ff0000",
    "#1bff00",
    "#0006ff",
    "#afafaf",
    "#6b272a"
];

export default {
    name: "MarkupAdminStatistics",
    components: {LineChart},
    data: () => ({
        selected: {
            types: [],
            users: [],
            seasons: [],
        },
        filter_values: {
            types: [],
            users: [],
            seasons: [],
        },
        statistics: null,
        is_statistics_loaded: true,
        is_filter_loaded: false,
        chartOptions: {
            locale: "ru-RU",
            scales: {
                x: {
                    type: 'time',
                    time: {
                        displayFormats: {
                            hour: {month: 'numeric', day: 'numeric', minute: 'numeric'}
                        }
                    },
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 10
                    }
                }
            },
        },
        charts: [
            {
                title: "Скорость разметки",
                data_label: "speed_plot",
            },
            {
                title: "Прогресс разметки",
                data_label: "progress_plot"
            },
        ],
        current_chart: null,
        not_loaded: true,
        priority_table_collapsed: true
    }),
    methods: {
        timedelta_to_readable: function (seconds) {
            seconds = seconds / 1000
            let days = Math.floor(seconds / (60 * 60 * 24));
            seconds -= days * (60 * 60 * 24);
            let hours = Math.floor(seconds / (60 * 60));
            seconds -= hours * (60 * 60);
            let minutes = Math.floor(seconds / 60);
            if (days + hours + minutes === 0) {
                return "Меньше минуты";
            }
            return period_format.days(days) + " " + period_format.hours(hours) + " " + period_format.minutes(minutes)
        },
        statisticsToSpeedPlot() {
            let basePeriod = 1000*60*60*24;
            let labels = [];
            let datasets = [];
            let values = {};
            this.statistics.markup.forEach((row) => {
                let date = Math.floor(row.marked_at.getTime() / basePeriod) * basePeriod;
                if(!labels.includes(date)){
                    labels.push(date);
                }
                if(!values[row.user_id]){
                    values[row.user_id] = new Map();
                }
                if(!values[row.user_id][date]){
                    values[row.user_id][date] = 0;
                }
                values[row.user_id][date] += 1;
            })
            labels.sort()
            labels = labels.map(value => new Date(value));

            let color_id = 0;
            this.statistics.users.forEach(user_id => {
                let data = [];
                labels.forEach(label => {
                    let time = label.getTime();
                    if(!values[user_id] || !values[user_id][time]){
                        data.push(0);
                    }else{
                        data.push(values[user_id][time]);
                    }
                })
                datasets.push({
                    label: user_id,
                    data: data,
                    fill: false,
                    backgroundColor: colors[color_id],
                    borderColor: colors[color_id],
                    cubicInterpolationMode: 'monotone',
                    tension: 0.4
                })
                color_id++;
            })

            this.statistics.speed_plot = {
                labels: labels,
                datasets: datasets
            }
        },
        statisticsToProgressPlot() {
            let basePeriod = 1000*60*60;
            let labels = [];
            this.statistics.markup.forEach((row) => {
                let date = Math.ceil(row.marked_at.getTime()/basePeriod)*basePeriod;
                if(!labels.includes(date)){
                    labels.push(date);
                }
            })
            labels.sort()
            labels = labels.map(value => new Date(value));

            this.statistics.progress_plot = {
                labels: labels,
                datasets: []
            }
            let color_id = 0;
            this.statistics.users.forEach(user_id => {
                let user_data = this.statistics.markup.filter(r => r.user_id === user_id);
                let data = [];
                labels.forEach(l => {
                    data.push(user_data.reduce((s, r) => s + (r.marked_at < l), 0));
                });
                this.statistics.progress_plot.datasets.push({
                    label: user_id,
                    data: data,
                    fill: false,
                    backgroundColor: colors[color_id],
                    borderColor: colors[color_id],
                    cubicInterpolationMode: 'monotone',
                    tension: 0.4
                })
                color_id++;
            });
        },
        statisticsPrioritiesCountTotal() {
            this.statistics.priorities_sum = {
                current: this.statistics.priorities.reduce((s, r) => s + Number(r.current), 0),
                total: this.statistics.priorities.reduce((s, r) => s + Number(r.total), 0),
            }
        },
        loadStatistics() {
            const filtersHash = (filters) => JSON.stringify(filters);
            this.is_statistics_loaded = false;
            let request_filters = filtersHash(this.selected);
            MarkupApi.get("admin/statistics", this.$store.state.token, this.selected, null, 30000).then(
                (resp) => {
                    if(request_filters !== filtersHash(this.selected)){
                        return;
                    }
                    this.statistics = resp.data;
                    this.statistics.markup.forEach((r, i) => {this.statistics.markup[i].marked_at = new Date(r.marked_at)});
                    this.statisticsToSpeedPlot();
                    this.statisticsToProgressPlot();
                    this.statisticsPrioritiesCountTotal();
                    this.is_statistics_loaded = true;
                    this.not_loaded = false;
                }
            ).catch(reason => {
                this.$emit("alert", "error", "Ошибка", "Не удалось загрузить статистику. Причина: "+reason.toString());
            });
        },
        loadFilters() {
            this.is_filter_loaded = false;
            MarkupApi.get("admin/statistic-filters", this.$store.state.token, null, null, 30000).then(
                (resp) => {
                    this.filter_values = resp.data;
                    this.is_filter_loaded = true;
                }
            ).catch(reason => {
                this.$emit("alert", "error", "Ошибка", "Не удалось загрузить фильтры. Причина: "+reason.toString());
            });
        }
    },
    computed: {
        priority_table_can_collapse: function (){
            return this.statistics.priorities.length > 5;
        },
        priority_table_height: function (){
            if(this.priority_table_can_collapse && this.priority_table_collapsed){
                return 350
            }else{
                return "100%"
            }
        }
    },
    mounted() {
        this.loadFilters();
    }
}
</script>

<template>
    <v-container fluid>
        <v-card class="mb-8 pa-4">
            <v-card-title>
                Фильтры
            </v-card-title>
            <v-row>
                <v-col class="v-col-12 v-col-lg-5">
                    <v-select label="Тип разметки" :items="filter_values.types" v-model="selected.types" @update:model-value="loadStatistics()" multiple clearable>
                    </v-select>
                </v-col>
                <v-col class="v-col-12 v-col-lg-5">
                    <v-select label="Разметчицы" :items="filter_values.users" v-model="selected.users" @update:model-value="loadStatistics()" multiple clearable>
                    </v-select>
                </v-col>
                <v-col class="v-col-12 v-col-lg-2">
                    <v-select label="Сезон" :items="filter_values.seasons" v-model="selected.seasons" @update:model-value="loadStatistics()" multiple clearable
                        item-value="season_id" item-title="title"
                    >
                    </v-select>
                </v-col>

                <v-col class="v-col-12 d-flex justify-end">
                    <v-btn :disabled="!is_filter_loaded || !is_statistics_loaded" v-on:click="loadStatistics()"
                           color="primary"
                    >
                        Reload
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
        <v-card>
            <v-row v-if="not_loaded && is_statistics_loaded">
                <v-col class="d-flex justify-center ma-2">
                    <p>
                        Настройте фильтры или нажмите кнопку [Reload], для того чтобы увидеть статистику.
                    </p>
                </v-col>
            </v-row>
            <v-row v-else-if="!is_statistics_loaded">
                <v-col class="d-flex justify-center">
                    <v-progress-circular
                        :size="50"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col class="v-col-12 v-col-md-4">
                    <v-card class="ma-4">
                        <v-card-title style="white-space: break-spaces;">
                            Времени за разметкой
                        </v-card-title>
                        <v-card-text>
                            {{ timedelta_to_readable(statistics.total_time) }}
                        </v-card-text>
                    </v-card>
                    <v-card class="ma-4">
                        <v-table :height="priority_table_height">
                            <thead class="position-sticky bg-white" style="top: 0;">
                            <tr>
                                <th class="text-left">Приоритет</th>
                                <th class="text-left">Размечено</th>
                                <th class="text-left">Всего</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                v-for="priority in statistics.priorities"
                                :key="priority.value"
                                :class="{'completed': priority.current === priority.total}">
                                <td>{{ priority.priority }}</td>
                                <td>{{ priority.current }}</td>
                                <td>{{ priority.total }}</td>
                            </tr>
                            </tbody>
                            <tfoot class="position-sticky bg-white" style="bottom: 0;">
                            <tr>
                                <th class="text-left">Итого</th>
                                <th class="text-left">{{statistics.priorities_sum.current}}</th>
                                <th class="text-left">{{statistics.priorities_sum.total}}</th>
                            </tr>
                            </tfoot>
                        </v-table>
                        <div v-show="this.priority_table_can_collapse">
                            <v-btn v-if="priority_table_collapsed" prepend-icon="mdi-arrow-down" variant="plain"
                                   v-on:click="priority_table_collapsed = false"
                            >
                                Развернуть
                            </v-btn>
                            <v-btn v-else prepend-icon="mdi-arrow-up" variant="plain"
                                   v-on:click="priority_table_collapsed = true">
                                Свернуть
                            </v-btn>
                        </div>
                    </v-card>
                </v-col>
                <v-col class="v-col-12 v-col-md-8">
                    <v-card  class="ma-4">
                        <v-tabs v-model="current_chart" grow>
                            <v-tab v-for="chart in charts" :key="chart.title">{{chart.title}}</v-tab>
                        </v-tabs>
                        <v-window v-model="current_chart">
                            <v-window-item
                                v-for="chart in charts"
                                :key="chart.title"
                            >
                                <LineChart :chart-data="statistics[chart.data_label]" :chart-options="chartOptions"></LineChart>
                            </v-window-item>
                        </v-window>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<style scoped>
.completed {
    background-color: #A0D7A0;

}
.completed:hover {
    background-color: #6CD56C!important;
}
</style>