class VueEvents {
    constructor() {
        this.events = {};
    }
    addCallback(event, func){
        if(!(event in this.events)){
            this.events[event] = [];
        }
        this.events[event].push(func);
    }
    on(event, params={}){
        if(event in this.events){
            this.events[event].forEach((func)=>func(params))
        }
    }
}

export default VueEvents;