<template>
  <v-container class="pa-0" fluid style="position: absolute; top: 80px">
    <v-row
        class="ma-0 pa-0"
    >
      <v-col
          cols="10"
          offset="1"
      >
        <v-card>
          <v-card-title>Регионы</v-card-title>
          <v-table>
            <thead>
            <tr>
              <th class="text-left">
                id
              </th>
              <th class="text-left">
                Название
              </th>
              <th class="text-left">
                Ссылка на борд
              </th>
              <th class="text-left">
                Тип
              </th>
              <th class="text-left">
                <v-btn color="info">
                  <v-icon v-on:click="openRegEditor({})">mdi-plus</v-icon>
                </v-btn>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="region in regionsList"
                :key="region.id"
            >
              <td>{{ region.id }}</td>
              <td>{{ region.name }}</td>
              <td>{{ region.board }}</td>
              <td>{{ region.type }}</td>
              <td class="d-flex">
                <v-btn
                    color="gray"
                    icon
                    size="small"
                    variant="outlined"
                    class="my-2"
                    v-on:click="openRegEditor(region)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                    color="error"
                    icon
                    size="small"
                    variant="outlined"
                    class="ml-2 my-2"
                    v-on:click="deleteRegion(region.id)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
            </tbody>
          </v-table>
        </v-card>
        <v-card class="mt-3">
          <v-card-title>Пользователи</v-card-title>
          <v-table>
            <thead>
            <tr>
              <th class="text-left">
                id
              </th>
              <th class="text-left">
                Имя пользователя
              </th>
              <th class="text-left">
                Email
              </th>
              <th class="text-left">
                Регионы
              </th>
              <th class="text-left">
                Админка
              </th>
              <th class="text-left">
                <v-btn color="info">
                  <v-icon v-on:click="openUserEditor({regions: [],editor:[]})">mdi-plus</v-icon>
                </v-btn>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="user in usersList"
                :key="user.id"
            >
              <td>{{ user.id }}</td>
              <td>{{ user.name }}</td>
              <td>{{ user.email }}</td>
              <td>
                <v-chip-group
                    active-class="primary--text"
                    column
                >
                  <v-chip
                      v-for="region in user.regions"
                      :key="region"
                  >
                    {{ getRegionById(region) }}
                  </v-chip>
                </v-chip-group>
              </td>
              <td>
                <v-chip-group
                    active-class="primary--text"
                    column
                >
                  <v-chip
                      v-for="editor in user.editor"
                      :key="editor"
                  >
                    {{ getAdminBoardByLink(editor) }}
                  </v-chip>
                </v-chip-group>
              </td>
              <td class="d-flex">
                <v-btn
                    color="gray"
                    icon
                    size="small"
                    variant="outlined"
                    class="my-2"
                    v-on:click="openUserEditor(user)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                    color="error"
                    icon
                    size="small"
                    variant="outlined"
                    class="ml-2 my-2"
                    v-on:click="deleteUser(user.id)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
            </tbody>
          </v-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
        v-model="showRegEditor"
    >
      <v-card min-width="450">
        <v-card-title>Настройки региона</v-card-title>
        <v-card-text>
          <v-text-field class="my-0"
                        label="Название"
                        v-model="newRegion.name"
          ></v-text-field>
          <v-text-field class="my-0"
                        label="Внутреняя ссылка"
                        v-model="newRegion.link"
          ></v-text-field>
          <v-text-field class="my-0"
                        label="Ссылка на дэшборд"
                        v-model="newRegion.board"
          ></v-text-field>
          <v-select class="my-0"
              :items="layoutTypes"
              item-title="name"
              item-value="type"
              label="Тип"
              v-model="newRegion.type"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn color="info" block @click="saveRegion">Сохранить</v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-btn color="info" block @click="showRegEditor = false">Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="showUserEditor"
    >
      <v-card min-width="450">
        <v-card-title>Настройки пользователя</v-card-title>
        <v-card-text>
          <v-text-field class="my-0"
                        label="Имя"
                        v-model="newUser.name"
          ></v-text-field>
          <v-text-field class="my-0"
                        label="Email"
                        v-model="newUser.email"
          ></v-text-field>
          <v-text-field class="my-0"
                        label="Пароль"
                        v-model="newUser.newPassword"
          ></v-text-field>
          <v-select class="my-0"
                    :items="regionsList"
                    item-title="name"
                    item-value="id"
                    label="Регион"
                    v-model="newUser.regions"
                    multiple
          ></v-select>
          <v-select
              class="my-0"
              :items="adminBoardsList"
              item-title="name"
              item-value="link"
              label="Админка"
              v-model="newUser.editor"
              multiple
          >
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn color="info" block @click="saveUser">Сохранить</v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-btn color="info" block @click="showUserEditor = false">Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import bcrypt from 'bcryptjs';

let apiUrl = process.env.VUE_APP_API_LINK

export default {
  name: "AdminManageRegions",
  data: () => ({
    regionsList: [],
    usersList: [],
    newRegion: {},
    newUser: {},
    showRegEditor: false,
    showUserEditor: false,
    layoutTypes: [
      {
        name: "Ростелеком+Нуэвас",
        type: "nrt"
      },
      {
        name: "Нуэвас",
        type: "n"
      },
    ],
    adminBoardsList: [
      {
        name: "Разметка по министерствам",
        link: "AdminSearchM"
      },{
      name: "Админка",
        link: "AdminManageRegions"
      },{
        name: "Разметка топиков",
        link: "AdminTopics"
      },
      {
        name: "Разметка эталонов",
        link: "AdminRef"
      },
      {
        name: "Лучшие сообщения",
        link: "AdminAddBestMsgs"
      },
      {
        name: "Тиндер сообщений",
        link: "AdminBestMsgsTinder"
      },
      {
        name: "Проверка разметки",
        link: "AdminChecker"
      },
      {
        name: "NER",
        link: "AdminNER"
      },
      {
        name: "Tinder v.2",
        link: "AdminTinder"
      },
      {
        name: "Тиндер по темам",
        link: "ThemeTinder"
      },
      {
        name: "Markup-Тиндер с темами",
        link: "MarkupThemeTinder"
      },
      {
        name: "Markup-Полная разметка",
        link: "MarkupThemeSelect"
      },
      {
        name: "Markup-Админка",
        link: "MarkupAdmin"
      },
      {
        name: "Тиндер эмоций",
        link: "EmotionTinder"
      },
      {
        name: "Markup-выбор эмоций",
        link: "MarkupEmotionSelect"
      },
      {
        name: "Markup-выбор позитивности",
        link: "MarkupSentimentSelect"
      },
    ]
  }),
  methods: {
    getData: function () {
      this.regionsList = []
      this.usersList = []
      axios({
        url: apiUrl + '/allRegions',
        headers: {"Authorization": `Bearer ${this.$store.state.token}`},
        method: 'GET'
      })
          .then(resp => {
            this.regionsList = resp.data
          })
          .catch(err => {
            console.log(err)
          })
      axios({
        url: apiUrl + '/allUsers',
        headers: {"Authorization": `Bearer ${this.$store.state.token}`},
        method: 'GET'
      })
          .then(resp => {
            this.usersList = resp.data
            this.usersList.forEach(user => {
              user.regions = JSON.parse(user.regions)
              user.editor = JSON.parse(user.editor)
            })
          })
          .catch(err => {
            console.log(err)
          })
    },
    openRegEditor: function (region) {
      this.newRegion = region
      this.showRegEditor = true
    },
    openUserEditor: function (user) {
      this.newUser = user
      this.showUserEditor = true
    },
    saveRegion: function () {
      this.showRegEditor = false
      if (this.newRegion.id) {
        axios({
          url: apiUrl + '/updateRegion',
          headers: {"Authorization": `Bearer ${this.$store.state.token}`},
          method: 'POST',
          data: this.newRegion
        })
            .then(resp => {
              console.log(resp)
              this.getData()
            })
            .catch(err => {
              console.log(err)
              this.getData()
            })
      } else {
        axios({
          url: apiUrl + '/addRegion',
          headers: {"Authorization": `Bearer ${this.$store.state.token}`},
          method: 'POST',
          data: this.newRegion
        })
            .then(resp => {
              console.log(resp)
              this.getData()
            })
            .catch(err => {
              console.log(err)
              this.getData()
            })
      }
    },
    saveUser: function () {
      this.showUserEditor = false
      if (this.newUser.newPassword) {
          this.newUser.password = bcrypt.hashSync(this.newUser.newPassword, 8)
      }
      this.newUser.regions = JSON.stringify(this.newUser.regions)
      this.newUser.editor = JSON.stringify(this.newUser.editor)
      if (this.newUser.id) {
        axios({
          url: apiUrl + '/updateUser',
          headers: {"Authorization": `Bearer ${this.$store.state.token}`},
          method: 'POST',
          data: this.newUser
        })
            .then(resp => {
              console.log(resp)
              this.getData()
            })
            .catch(err => {
              console.log(err)
              this.getData()
            })
      } else {
        this.showUserEditor = false
        axios({
          url: apiUrl + '/addUser',
          headers: {"Authorization": `Bearer ${this.$store.state.token}`},
          method: 'POST',
          data: this.newUser
        })
            .then(resp => {
              console.log(resp)
              this.getData()
            })
            .catch(err => {
              console.log(err)
              this.getData()
            })
      }
    },
    deleteRegion: function (id) {
      axios({
        url: apiUrl + '/deleteRegion',
        headers: {"Authorization": `Bearer ${this.$store.state.token}`},
        method: 'POST',
        data: {id: id}
      })
          .then(resp => {
            console.log(resp)
            this.getData()
          })
          .catch(err => {
            console.log(err)
            this.getData()
          })
    },
    deleteUser: function (id) {
      axios({
        url: apiUrl + '/deleteUser',
        headers: {"Authorization": `Bearer ${this.$store.state.token}`},
        method: 'POST',
        data: {id: id}
      })
          .then(resp => {
            console.log(resp)
            this.getData()
          })
          .catch(err => {
            console.log(err)
            this.getData()
          })
    },
    getRegionById: function (id) {
      return this.regionsList.find(x => x.id === id).name
    },
    getAdminBoardByLink: function (link) {
      return this.adminBoardsList.find(x => x.link === link).name
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped>

</style>