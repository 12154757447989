<script>

import MarkupApi from "@/plugins/markup_api";
import MarkupBase from "@/components/Markup/Base.vue"


export default {
    name: "MarkupThemeBase",
    extends: MarkupBase,
    data: () => ({
        theme_data: null,
        themes_list: null,
        spheres_list: null,
    }),
    methods: {
        loadThemes: function () {
            MarkupApi.get("theme_dir", this.$store.state.token)
                .then(resp => {
                    let theme_data = {};
                    for (const [type, data] of Object.entries(resp.data)) {
                        let themes = new Map();
                        let spheres = new Set();
                        for (const theme of data) {
                            theme.theme_id = Number(theme.theme_id);
                            if (theme.theme_id < 900) {
                                themes.set(theme.theme_id, theme);
                                spheres.add(theme.sphere_name);
                            }
                        }
                        theme_data[type] = {
                            themes_list: themes,
                            spheres_list: Array.from(spheres)
                        };
                    }
                    this.theme_data = theme_data;
                    this.checkLoadState();
                    this.selectThemesType();
                }).catch(reason => {
                console.log(reason);
                this.errorAlert(4904981910);
            });
        },
        checkLoadState: function () {
            if (this.message != null && this.theme_data != null) {
                this.is_loaded = true;
            }
        },
        selectThemesType: function () {
            if(!this.is_loaded){
                return;
            }
            if(!this.message){
                return;
            }
            let current_themes = null;
            if(this.message.condition.themes_type in this.theme_data){
                current_themes = this.theme_data[this.message.condition.themes_type];
            }else{
                current_themes = this.theme_data["default"];
            }
            this.themes_list = current_themes.themes_list;
            this.spheres_list = current_themes.spheres_list;

        },
    },
    mounted() {
        this.loadThemes();
        this.events.addCallback("messageLoaded", this.selectThemesType)
    }
}
</script>