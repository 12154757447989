<template>
    <v-card class="mt-3 pa-2">
        <v-row>
            <v-col><h4>Сферы</h4></v-col>
        </v-row>
        <v-row id="sphere-selector" class="ma-1">
            <div v-for="sphere in spheres" :key="sphere" cols="auto">
                <v-btn
                    size="small"
                    class="ma-1"
                    v-on:click="() => {this.opened_sphere = sphere}"
                    :variant="opened_sphere == sphere ? 'outlined': 'elevated'"
                >
                    {{ sphere }}
                </v-btn>
            </div>
        </v-row>
        <v-divider></v-divider>
        <v-row>
            <v-col><h4>Темы</h4></v-col>
        </v-row>
        <v-row class="ma-1">
            <div v-for="theme in themes.values()" :key="theme.theme_id">
                <v-btn
                    size="small"
                    class="ma-1"
                    v-on:click="selectTheme(theme.theme_id)"
                    :color="themeColor(theme.theme_id)"
                    v-if="opened_sphere == theme.sphere_name"
                >
                    {{ theme.theme_name }}
                </v-btn>
            </div>
        </v-row>
    </v-card>
</template>

<script>

export default {
    name: "MarkupControlsThemeSelector",
    data: () => ({
        opened_sphere: ""
    }),
    props: [
        'spheres',
        'themes',
        "themeColor",
        "selectTheme"
    ],
    mounted() {
        if(this.spheres.length > 0){
            this.opened_sphere = this.spheres[0];
        }
    }
}
</script>