<template>
    <alerts-list ref="alerts"></alerts-list>
    <v-container fluid class="pa-0" style="position: absolute; top: 80px">
        <v-row class="ma-0 pa-0">
            <v-col cols="10" offset="1">
                <markup-parts-statistics ref="statistics" :markup_type="markup_type" :error_alert="errorAlert"/>
                <markup-parts-body :is_loaded="is_loaded" :message="message" :instruction="instruction">
                    <template v-slot:markup>
                        <v-divider></v-divider>
                        <v-row>
                            <v-col><h4>Тональность</h4></v-col>
                        </v-row>
                        <v-row id="sentiment-switch" class="ma-1">
                            <v-col v-if="sentimetnUI">
                                <v-slider
                                    v-model:model-value="markup.value"
                                    :min="0" :max="4"
                                    show-ticks="always"
                                    :ticks="sentimentEmojis"
                                    step="1"
                                >
                                </v-slider>
                            </v-col>
                            <v-col v-else class="d-flex flex-row justify-space-evenly">
                                <v-btn
                                    v-for="sentimentLevel in Object.keys(sentimentEmojis)" v-bind:key="sentimentLevel"
                                    @click="markup.value=sentimentLevel" v-text="sentimentEmojis[sentimentLevel]"
                                    :color="markup.value == sentimentLevel ? 'green': 'white' "
                                />
                            </v-col>
                        </v-row>
                        <v-row class="mr-1">
                            <v-col class="d-flex flex-row justify-space-between pa-0">
                                <div>
                                    <v-checkbox label="Специфичное" v-model="markup.isSpecific"></v-checkbox>
                                </div>
                                <div>
                                    <v-switch :label="sentimetnUI ? 'Слайдер': 'Кнопки'" v-model="sentimetnUI"></v-switch>
                                </div>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:controls>
                        <v-row>
                            <v-col>
                                <v-btn
                                    color="info"
                                    v-on:click="moveBack()"
                                    :disabled="previous_markup_id == null"
                                >
                                    Назад
                                </v-btn>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col>
                                <v-btn density="default" color="green" class="float-right"
                                       v-on:click="setMarkup(markup)"
                                >
                                    Сохранить
                                </v-btn>
                            </v-col>
                        </v-row>
                    </template>
                </markup-parts-body>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import MarkupSentimentBase from "@/components/Markup/Sentiment/Base.vue";
import AlertsList from "@/components/AlertsList.vue";
import MarkupPartsStatistics from "@/components/Markup/Parts/Statistics.vue";
import MarkupPartsBody from "@/components/Markup/Parts/Body.vue";

export default {
    name: "MarkupSentimentSelect",
    components: {AlertsList, MarkupPartsStatistics, MarkupPartsBody},
    extends: MarkupSentimentBase,
    data: () => ({
        markup_type: "SentimentSelect",
        sentimentEmojis: {
            0: "😡",
            1: "😟",
            2: "😐",
            3: "🙂",
            4: "😁"
        },
        sentimetnUI: true,
    }),
    methods: {
        defaultMarkup: function () {
            return {
                isSpecific: false,
                value: 2,
            }
        }
    }
}
</script>