<template>
  <v-container fluid class="pa-0" style="position: absolute; top: 80px">
    <v-row
        class="ma-0 pa-0"
    >
      <v-col
          cols="10"
          offset="1"
      >
        <v-card class="pa-3 mt-3" min-width="380">
          <v-row>
            <v-text-field v-model="searchTopic" label="Тег для поиска" class="ma-3"></v-text-field>
            <v-btn v-on:click="filterTopics(searchTopic)" class="ma-3">Отфильтровать</v-btn>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-btn v-on:click="resetSelected" class="ma-3">Сбросить поиск</v-btn>
            <v-btn v-on:click="showStat" class="ma-3">Показать статистику</v-btn>
          </v-row>
        </v-card>


        <v-card class="mt-3">
          <v-table>
            <thead>
            <tr>
              <th class="text-left">
                ID
              </th>
              <th class="text-left">
                Имя топика
              </th>
              <th class="text-left">
                Сообщений
              </th>
              <th class="text-left">
                ПодТопиков всего
              </th>
              <th class="text-left">
                Действия
              </th>
              <th class="text-left">
                Статус
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="topic in topics.slice(limit*(page-1), limit*page)" :key="topic.Parent_ID">
              <td>{{ topic.Parent_ID }}</td>
              <td>{{ topic.Parent_Name }}</td>
              <td>{{ topic.msgCount }}</td>
              <td>{{ JSON.parse(topic.Topics).length }}</td>
              <td>
                <v-row>
                  <v-btn
                      v-on:click="selectTopic(topic)" v-if="topic.LowLevel == 1"
                      icon="mdi-file-tree"
                      color="green-lighten-5"
                      v-tooltip="'Показать родителей'"
                  ></v-btn>
                  <v-btn
                      icon="mdi-file-tree"
                      color="red-lighten-5"
                      v-tooltip="'Показать детей'"
                      v-on:click="showChildren(topic)" v-if="topic.LowLevel == 0"
                  ></v-btn>
                  <v-btn
                      icon="mdi-text"
                      color="purple-lighten-5"
                      class="ml-3"
                      v-tooltip="'Показать сообщения'"
                      v-on:click="showMsgs(topic)"
                  ></v-btn>
                  <v-btn
                      icon="mdi-playlist-plus"
                      color="blue-lighten-5"
                      class="ml-3"
                      v-tooltip="'Выбрать в корпус'"
                      v-on:click="addTopic(topic)"
                  ></v-btn>
                </v-row>
              </td>
              <td>
                <v-btn
                    v-if="topic.TagUser"
                    icon="mdi-alert-octagon"
                    color="red"
                    v-tooltip="'Протегированно ' + topic.TagUser + ' как ' + topic.TagType + ' в ' + topic.TagSphere + ', ' + topic.TagTheme"
                    v-on:click="addTopic(topic)"
                ></v-btn>
              </td>
            </tr>
            </tbody>
          </v-table>
          <v-pagination
              v-model="page"
              :length="maxPage"
              rounded="circle"
          ></v-pagination>
        </v-card>

        <v-card class="mt-3" v-if="showMsgsList">
          <v-table>
            <thead>
            <tr>
              <th class="text-left">
                Текст
              </th>
              <th class="text-left">
                Топик
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="msg in msgs.slice(limit*(pageM-1), limit*pageM)" :key="msg.text">
              <td>{{ msg.text }}</td>
              <td>{{ msg.topics }}</td>
            </tr>
            </tbody>
          </v-table>
          <v-pagination
              v-model="pageM"
              :length="maxPageM"
              rounded="circle"
          ></v-pagination>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
        v-model="addDialog"
    >
      <v-card max-height="640">
        <v-card-title>Отобранные топики:</v-card-title>
        <v-table>
          <thead>
          <tr>
            <th class="text-left">
              ID
            </th>
            <th class="text-left">
              Имя топика
            </th>
            <th class="text-left">
              Сообщений
            </th>
            <th class="text-left">
              Действия
            </th>
            <th class="text-left">

            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="topic in addArr" :key="topic.Parent_ID">
            <td>{{ topic.Parent_ID }}</td>
            <td>{{ topic.Parent_Name }}</td>
            <td>{{ topic.msgCount }}</td>
            <td>
              <v-radio-group v-model="topic.addType">
                <v-radio
                    label="Игнорировать"
                    color="gray"
                    value="ignore"
                ></v-radio>
                <v-radio
                    label="Добавить"
                    color="green"
                    value="add"
                ></v-radio>
                <v-radio
                    label="Вычесть"
                    color="red"
                    value="minus"
                ></v-radio>
              </v-radio-group>
            </td>
            <td>
              <v-btn
                  icon="mdi-text"
                  color="purple-lighten-5"
                  class="ml-3"
                  v-tooltip="'Показать сообщения'"
                  v-on:click="showMsgs(topic)"
              ></v-btn>
            </td>
            <td>
              <v-btn
                  v-if="topic.TagUser"
                  icon="mdi-alert-octagon"
                  color="red"
                  v-tooltip="'Протегированно ' + topic.TagUser + ' как ' + topic.TagType + ', ' + topic.TagSphere + ', ' + topic.TagTheme"
                  v-on:click="addTopic(topic)"
              ></v-btn>
            </td>
          </tr>
          </tbody>
        </v-table>
      </v-card>
      <v-card v-if="showMsgsList" class="pa-3 mt-3" min-height="640">
        <v-row v-if="showMsgsList">
          <v-card-title>
            Сообщения в топике:
          </v-card-title>
          <v-spacer></v-spacer>
          <v-btn
              icon="mdi-eye-off"
              class="mr-7"
              v-tooltip="'Скрыть сообщения'"
              v-on:click="showMsgsList = !showMsgsList"
          ></v-btn>
        </v-row>
        <v-table v-if="showMsgsList">
          <thead>
          <tr>
            <th class="text-left">
              Текст
            </th>
            <th class="text-left">
              Топик
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="msg in msgs.slice(limit*(pageM-1), limit*pageM)" :key="msg.text">
            <td>{{ msg.text }}</td>
            <td>{{ msg.topics }}</td>
          </tr>
          </tbody>
        </v-table>
        <v-pagination
            v-if="showMsgsList"
            v-model="pageM"
            :length="maxPageM"
            rounded="circle"
        ></v-pagination>
      </v-card>
      <v-card class="pa-3 mt-3" min-width="380">
        <v-card-title>Размечаем в:</v-card-title>
        <v-row>
          <v-select
              class="pa-3"
              :items="sphere"
              v-model="selectedSphere"
              label="Сфера"
          ></v-select>
          <v-select
              v-if="selectedSphere !== '' && selectedSphere !== 'Своя сфера' && selectedSphere !== 'Мусор'"
              class="pa-3"
              :items="theme[selectedSphere]"
              v-model="selectedTheme"
              label="Тема"
          ></v-select>
          <v-text-field
              v-if="selectedSphere === 'Своя сфера'"
              class="pa-3"
              v-model="selectedTheme"
              label="Своя тема"
          ></v-text-field>
        </v-row>
        <v-btn v-on:click="addMsgs" block color="blue" style="color: white!important" :disabled="selectedSphere === ''">
          Разметить
        </v-btn>
      </v-card>
    </v-dialog>
    <v-dialog v-model="statDialog">
      <v-card class="pa-3 mt-3" min-width="380">
        <v-table>
          <thead>
          <tr>
            <th class="text-left">
              Сфера
            </th>
            <th class="text-left">
              Тема
            </th>
            <th class="text-left">
              Топиков
            </th>
            <th class="text-left">
              Сообщений
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="themeStat in stat" :key="themeStat.theme">
            <td>{{ themeStat.sphere }}</td>
            <td>{{ themeStat.theme }}</td>
            <td>{{ themeStat.topics }}</td>
            <td>{{ themeStat.msgs }}</td>
          </tr>
          </tbody>
        </v-table>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'

let apiUrl = process.env.VUE_APP_API_LINK

function sortByKey(array, key) {
  return array.sort(function(a, b) {
    var x = a[key]; var y = b[key];
    return ((x > y) ? -1 : ((x < y) ? 1 : 0));
  });
}

export default {
  name: "AdminTopics",
  data: () => ({
    topics: [],
    allTopics: [],
    loadingState: false,
    searchTopic: 42,
    data: {},
    limit: 20,
    page: 1,
    pageM: 1,
    maxPage: 1,
    maxPageM: 1,
    addDialog: false,
    selectedTopic: {},
    children: [],
    msgs: [],
    selectedSphere: '',
    showMsgsList: false,
    selectedTheme: '',
    addArr: [],
    sphere: [
      'Своя сфера',
      'Мусор',
      'Медицина', 'Экономика, производство и благосостояние', 'Спорт и спортивные мероприятия', 'ВСО', 'Соцзащита и обеспеченность', 'Благоустройство и ЖКХ', 'Безопасность', 'Образование и наука', 'Строительство и развитие инфраструктуры', 'Экология', 'Дороги и транспорт', 'Культура, мероприятия, туризм', 'Общественно-политическая повестка', 'Нацпроекты'
    ],
    theme: {
      'Спорт и спортивные мероприятия': ['Проф. Спорт', 'Любительский спорт', 'Международный спорт', 'Спортивная инфораструктура', 'Детский спорт + секции', 'Мероприятия'],
      'Соцзащита и обеспеченность': ['Поддержка безработных', 'Выплаты общие', 'Соц. Найм жилья', 'Люди с ОВЗ и инфораструктура', 'Поддержка семей с детьми', 'Поддержка малоимущих', 'Поддержка пенсионеров', 'Соц. Службы и волентерские движения'],
      'ВСО': ['Военные действия', 'Восстановление мирной жизни', 'снежное', 'Возвращение', 'Жертвы', 'Беженцы', 'Поддержка', 'Противники', 'Волонтеры'],
      'Безопасность': ['Бродячие животные', 'Коррупция', 'Кражи и мошенничество', 'Криминал', 'Правонарушения', 'Проишествия', 'Митинги/пикеты/протесты', 'Пропавшие люди', 'Проишествия с участием детей или их правами', 'Теневая экономика', 'Пожары', 'ДТП'],
      'Медицина': ['Ковид', 'Эпид. Ситуация', 'Диспансеризация/прививки взр (справки, мед.проф)', 'Скорая медицинская помощь', 'Стационары', 'Амбулаторное лечение', 'Коррупция', 'Качество оказания услуг', 'Лекарственное обеспечение', 'Больничные', 'Управление в медицине', 'Педиатрия', 'Проф. Специалисты и исследования'],
      'Благоустройство и ЖКХ': ['Водоснабжение и водоотведение', 'Отопление', 'Электричество', 'Освещение', 'Газоснабжение и газификация', 'Общественные территории', 'Дворовые территории', 'Капитальный ремонт', 'Управляющие компании', 'Уборка территорий и вывоз мусора', 'Коммунальные платежи', 'Ливневки/подтопления'],
      'Экономика, производство и благосостояние': ['Аграрный сектор', 'Промышленность региона', 'Малый бизнес', 'Импортозамещение', 'Иностранные компании', 'Инвестиции', 'Санкции', 'Финансовый сектор (банковский?) болтовня вокруг финансов', 'Гос. Закупки', 'Цены и ретейл', 'Цены на продукты', 'Нарушения/качество услуг', 'Меры поддержки физлиц', 'меры поддержки предприятий', 'Международные/межрегиональные соглашения'],
      'Образование и наука': ['Дошкольное образование', 'Начальное и среднее образование', 'Среднее-профессиональное образование', 'Вышее', 'ДПО', 'Кружки, секции', 'Научная деятельность', 'Управление в образовании'],
      'Строительство и развитие инфраструктуры': ['Строительство многоквартирных домов', 'Дольщики', 'ИЖС', 'Градостроительная политика', 'Соц. Инфраструктура'],
      'Экология': ['Загрязнение воздуха', 'Загрязнение воды', 'Переработка мусора', 'Прочие загрязнения', 'Эко-активисты и экопросвет', 'Состояние лесов', 'Состояние животных ресурсов', 'Охота-рыбалка', 'Национальные парки'],
      'Дороги и транспорт': ['Строительство дорог и развязок', 'Качество и состояние дорог', 'Обсуждение пдд и правил дорожного движения', 'Временные ограничения', 'Уборка дорог', 'Загруженность общественного транспорта', 'Маршруты общественного транспорта', 'Оплата общественного транспорта', 'состояние общественного транспорта и инфраструктуры', 'Железнодорожный транспорт', 'Речной транспорт', 'Авиатранспорт', 'Метро'],
      'Культура, мероприятия, туризм': ['Деятельность учреждений культуры', 'Объекты культуры и рекреации', 'Туристическая инфраструктура', 'Форумы/фестивали/праздники', 'Концерты', 'Религия и инфраструктура', 'Самодеятельные коллективы', 'Федеральный и международный туризм', 'Управление в культуре'],
      'Общественно-политическая повестка': ['Международная политика', 'Международная экономика', 'Руководство страны', 'Руководство региона', 'Жалобы, письма, обращения', 'Протесты'],
      'Нацпроекты': ['Все']
    },
    stat: [],
    statDialog: false
  }),
  methods: {
    getTopics: function (lowLevel) {
      this.loadingState = true
      this.topics = []
      axios.get(apiUrl + "/allTopics?lowLevel=" + lowLevel, {headers: {"Authorization": `Bearer ${this.$store.state.token}`}})
          .then(resp => {
            this.topics = resp.data
            this.allTopics = resp.data
            this.maxPage = Math.ceil(this.topics.length / this.limit)
            this.loadingState = false
          })
    },
    getMsgsInTopic: function (topic) {
      let allTopicArr = this.allTopics
      let topicData = allTopicArr.find(x => x.Parent_ID === +topic);
      if (topicData.LowLevel == 1) {
        // console.log(topic + " нижний уроень " + topicData.msgCount)
        this.data = topicData.msgCount
        return {msgsCount: topicData.msgCount, child: []}
      } else {
        let subTopicsList = JSON.parse(topicData.Topics)
        let subTopicClassesList = []

        if (!subTopicsList.includes(+topicData.Child_Left_ID)) {
          subTopicClassesList.push(+topicData.Child_Left_ID)
        }
        if (!subTopicsList.includes(+topicData.Child_Right_ID)) {
          subTopicClassesList.push(+topicData.Child_Right_ID)
        }
        let msgCount = 0

        for (let subTopic in subTopicsList) {
          let subD = this.getMsgsInTopic(subTopicsList[subTopic])
          msgCount = msgCount + subD.msgsCount
        }
        // console.log(topic + " верхний уровень " + msgCount)
        this.data = {msgsCount: msgCount, child: subTopicClassesList}
      }
    },
    getAllParents: function (topic) {
      let allTopicArr = this.allTopics
      // console.log(topic)
      let topicParents = allTopicArr.filter(x => JSON.parse(x.Topics).includes(topic));
      this.data = topicParents
      return topicParents
    },
    selectTopic: function (topic) {
      this.selectedTopic = topic
      if (typeof topic === "object") {
        this.topics = this.getAllParents(topic.Parent_ID)
      } else {
        this.topics = this.getAllParents(topic)
      }
    },
    resetSelected: function () {
      this.selectedTopic = {}
      this.getTopics(999)
    },
    getChildren: function (topic) {
      let allTopicArr = this.allTopics
      let ids = [topic.Child_Left_ID, topic.Child_Right_ID]
      for (let id in ids) {
        if (ids[id]) {
          let cTopic = allTopicArr.find(x => +x.Parent_ID === +ids[id])
          if (cTopic.LowLevel == 0) {
            this.topics.push(cTopic)
            this.getChildren(cTopic)
          }
        }
      }
    },
    showChildren: function (topic) {
      let allTopicArr = this.allTopics
      this.topics = []
      this.selectedTopic = topic
      this.getChildren(topic)
      let tmpArr = JSON.parse(topic.Topics)
      for (let id in tmpArr) {
        if (tmpArr[id]) {
          let cTopic = allTopicArr.find(x => +x.Parent_ID === +tmpArr[id])
          this.topics.push(cTopic)
        }
      }
    },
    showMsgs: function (topic) {
      this.showMsgsList = true
      this.msgs = []
      let topics = JSON.parse(topic.Topics)
      if (topics.length > 0) {
        for (let t in topics) {
          axios.get(apiUrl + "/getMsgs?topic=" + topics[t], {headers: {"Authorization": `Bearer ${this.$store.state.token}`}})
              .then(resp => {
                this.msgs = this.msgs.concat(resp.data)
              })
        }
      } else {
        axios.get(apiUrl + "/getMsgs?topic=" + topic.Parent_ID, {headers: {"Authorization": `Bearer ${this.$store.state.token}`}})
            .then(resp => {
              this.msgs = resp.data
            })
      }
    },
    filterTopics: function (search) {
      this.topics = this.topics.filter(x => x.Parent_Name.includes(search))
    },
    addTopic: function (topic) {
      let allTopicArr = this.allTopics
      this.addDialog = true
      this.showMsgsList = false
      this.selectedSphere = ""
      this.selectedTheme = ""
      this.addArr = []
      this.msgs = []
      if (topic.LowLevel === 1) {
        topic.addType = "add"
        this.addArr.push(topic)
      } else {
        let arr = JSON.parse(topic.Topics)
        for (let i in arr) {
          let t = allTopicArr.find(x => +x.Parent_ID === +arr[i])
          if (t.TagUser) {
            t.addType = "ignore"
          } else {
            t.addType = "add"
          }
          this.addArr.push(t)
        }
      }
    },
    showStat: function () {
      axios({
        url: apiUrl + '/getStat',
        headers: {"Authorization": `Bearer ${this.$store.state.token}`},
        method: 'GET'
      })
          .then(resp => {
            console.log(resp)
            this.stat = sortByKey(resp.data, "msgs")
            this.statDialog = true
          })
          .catch(err => {
            console.log(err)
          })
    },
    addMsgs: function () {
      let addArr = this.addArr
      for (let t in addArr) {
        t = addArr[t]
        if (t.addType === "add" || t.addType === "minus") {
          // console.log(t + ' - добавлен')
          axios({
            url: apiUrl + '/addTagForTopic',
            headers: {"Authorization": `Bearer ${this.$store.state.token}`},
            method: 'POST',
            data: {
              user: this.$store.state.user,
              sphere: this.selectedSphere,
              theme: this.selectedTheme,
              type: t.addType,
              topicId: t.Parent_ID
            }
          })
              .then(resp => {
                this.addDialog = false
                this.addArr = []
                this.getTopics(999)
                console.log(resp)
              })
              .catch(err => {
                this.getTopics(999)
                console.log(err)
              })
        } else {
          // console.log(t + ' - проигнорировано')
        }

      }
    }
  },
  mounted() {
    this.getTopics(999)
  },
  watch: {
    topics: {
      handler() {
        this.page = 1
        this.maxPage = Math.ceil(this.topics.length / this.limit)
      },
      deep: true
    },
    msgs: {
      handler() {
        this.pageM = 1
        this.maxPageM = Math.ceil(this.msgs.length / this.limit)
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>