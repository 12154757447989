import axios from "axios";

const apiUrl = process.env.VUE_APP_API_LINK;

let MarkupApi = {
    _prepareHeaders: function(token, headers){
        let _headers = {"Authorization": `Bearer ${token}`}
        if(headers != null){
            for (const key in headers) {
                _headers[key] = headers[key];
            }
        }
        return _headers;
    },
    get: function (path, token, params=null, headers=null, timeout=15000){
        headers = this._prepareHeaders(token, headers);
        if(params == null){
            params = {}
        }
        return axios.get(apiUrl + "/markup/" + path, {headers: headers, params: params, timeout: timeout});
    },
    post: function (path,token, data=null, headers=null, timeout=15000){
        headers = this._prepareHeaders(token, headers);
        if(data == null) {
            data = {}
        }
        return axios.post(apiUrl + "/markup/" + path, data, {headers: headers, timeout: timeout})
    }
}

export default MarkupApi;