<template>
    <alerts-list ref="alerts"></alerts-list>
    <v-container fluid class="pa-0" style="position: absolute; top: 80px">
        <v-row class="ma-0 pa-0">
            <v-col cols="10" offset="1">
                <markup-parts-statistics ref="statistics" :markup_type="markup_type" :error_alert="errorAlert"/>
                <markup-parts-body :is_loaded="is_loaded" :message="message"  :instruction="instruction">
                    <template v-slot:markup>
                        <v-divider></v-divider>
                        <v-row>
                            <v-col><h4>Темы для подтверждения</h4></v-col>
                        </v-row>
                        <v-row id="selected-themes" class="ma-1">
                            <v-col>
                                <v-chip
                                    v-for="theme_id in message.condition.themes"
                                    :key="theme_id"
                                    class="ma-1" variant="outlined"
                                >
                                    {{ themes_list.get(theme_id).sphere_name }}
                                    - {{ themes_list.get(theme_id).theme_name }}
                                </v-chip>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:controls>
                        <v-row>
                            <v-col class="v-col-12 v-col-lg-5">
                                <v-btn
                                    color="info"
                                    v-on:click="moveBack()"
                                    :disabled="previous_markup_id == null"
                                >
                                    Назад
                                </v-btn>
                            </v-col>
                            <v-col class="v-col-12 v-col-lg-2 d-flex justify-space-between pl-8 pr-8" tabindex="0"
                                   @keydown="onKeyDown" ref="markup"
                            >
                                <v-btn density="default" icon="mdi-thumb-down"
                                       :color="getBtnColor(false)"
                                       ref="btn_disagree"
                                       v-on:click="setMarkup({agree: false})">
                                </v-btn>
                                <v-btn density="default" icon="mdi-thumb-up"
                                       :color="getBtnColor(true)"
                                       ref="btn_agree"
                                       v-on:click="setMarkup({agree: true})">
                                </v-btn>
                            </v-col>
                        </v-row>
                    </template>
                </markup-parts-body>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import AlertsList from "@/components/AlertsList.vue";
import MarkupPartsStatistics from "@/components/Markup/Parts/Statistics.vue";
import MarkupPartsBody from "@/components/Markup/Parts/Body.vue";
import MarkupThemeBase from "@/components/Markup/Theme/Base.vue";


export default {
    name: "MarkupThemeTinder",
    components: {AlertsList, MarkupPartsStatistics, MarkupPartsBody},
    extends: MarkupThemeBase,
    data: () => ({
        markup_type: "ThemeTinder",
    }),
    methods: {
        getBtnColor: function (condition) {
            if (
                this.previous_markup_id == null
                && !!this.previous_markup_value
                && this.previous_markup_value.agree === condition
            ) {
                return 'white';
            }
            if (condition) {
                return 'green';
            } else {
                return 'red';
            }
        },
        onKeyDown: function (e) {
            switch (e.key) {
                case 'ArrowLeft':
                case 'a':
                case 'ф':
                    this.setMarkup(false);
                    break;
                case 'ArrowRight':
                case 'd':
                case 'в':
                    this.setMarkup(true);
                    break;
            }
        },
        focusControls: function () {
            this.$nextTick(() => { // because now it dosnt exists!
                if(this.$refs.markup){
                    this.$refs.markup.$el.focus();
                }
            });
        },
        defaultMarkup: function () {return {agree: true}},
    },
    mounted() {
        this.events.addCallback('messageLoaded', this.focusControls);
    }
}
</script>