<template>
    <div v-if="!is_loaded">
        <v-card class="mt-3 pa-2 d-flex justify-center">
            <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
            ></v-progress-circular>
        </v-card>
    </div>
    <div v-else>
        <div v-if="!message.markup_id">
            <v-card class="mt-3 pa-2 d-flex justify-center">
                <h3>Нет сообщений для разметки.</h3>
            </v-card>
        </div>
        <div v-else>
            <v-card id="message" class="mt-3 pa-2">
                <markup-parts-info :text="instruction"></markup-parts-info>
                <slot name="message">
                    <v-row id="message-text" class="mb-3">
                        <v-col>
                            <h3 class="break-space">{{ message.text }}</h3>
                            <div v-if="message.condition.link">
                                Оригинал: <a :href="message.condition.link" target="_blank">открыть</a>
                            </div>
                        </v-col>
                    </v-row>
                </slot>
                <slot name="markup">
                    MARKUP
                </slot>
            </v-card>
            <slot name="additional-controls">
            </slot>
            <v-card id="controls" class="mt-5 pa-2 mb-2" style="position: sticky;bottom: 10px;">
                <slot name="controls">
                    CONTROLS
                </slot>
            </v-card>
        </div>
    </div>
</template>

<script>
import MarkupPartsInfo from "@/components/Markup/Parts/Info.vue";

export default {
    name: "MarkupPartsBody",
  components: {MarkupPartsInfo},
    props: [
        'is_loaded',
        'message',
        'instruction'
    ],
}
</script>

<style scoped>
.break-space {
    white-space: break-spaces;
}
</style>
