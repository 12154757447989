<script>

import MarkupBase from "@/components/Markup/Base.vue"


export default {
    name: "MarkupEmotionBase",
    extends: MarkupBase,
    data: () => ({
        emotions_list: [
            {name: "Без эмоции", id: "neutral",},
            {name: "Злость", id: "angry",},
            {name: "Интерес", id: "interest",},
            {name: "Вина", id: "guilty",},
            {name: "Отвращение", id: "disgust",},
            {name: "Грусть", id: "sadness",},
            {name: "Радость", id: "joy",},
            {name: "Страх", id: "fear",},
            {name: "Удивление", id: "surprise",},
        ]
    }),
}
</script>