<script>
import MarkupApi from "@/plugins/markup_api";

export default {
    name: "MarkupAdminData",
    data: () => (
        {
            selected: {
                types: [],
                users: [],
                seasons: [],
                search_text: '',
                completed: []
            },
            filter_values: {
                types: [],
                users: [],
                seasons: [],
            },
            is_filter_loaded: false,
            data: null,
            is_data_loaded: false,
            data_headers: [
                {title: 'Текст', key: 'text'},
                {title: 'Разметка', key: 'type'},
                {title: 'Разметчик', key: 'user_id'},
                {title: 'Сезон', key: 'season_title'},
            ],
            current_page: 1,
            rows_per_page: 10,
        }
    ),
    methods: {
        loadData() {
            this.is_data_loaded = false;
            MarkupApi.get("admin/markup-data", this.$store.state.token, null, null, 30000).then(
                (resp) => {
                    this.data = resp.data;
                    this.is_data_loaded = true;
                }
            ).catch(reason => {
                this.$emit("alert", "error", "Ошибка", "Не удалось загрузить данные. Причина: "+reason.toString());
            });
        },
        loadFilters() {
            this.is_filter_loaded = false;
            MarkupApi.get("admin/statistic-filters", this.$store.state.token, null, null, 30000).then(
                (resp) => {
                    this.filter_values = resp.data;
                    this.is_filter_loaded = true;
                }
            ).catch(reason => {
                this.$emit("alert", "error", "Ошибка", "Не удалось загрузить фильтры. Причина: "+reason.toString());
            });
        },
    },
    computed: {
        view_data() {
            return this.filtered_data.slice(
                (this.current_page - 1) * this.rows_per_page,
                this.current_page * this.rows_per_page,
            );
        },
        filtered_data() {
            return this.data.filter((row) => {
                this.current_page = 1;
                return (
                    row.text.includes(this.selected.search_text)
                    && (this.selected.completed.length === 0 || this.selected.completed.includes(row.completed))
                    && (this.selected.types.length === 0 || this.selected.types.includes(row.type))
                    && (this.selected.users.length === 0 || this.selected.users.includes(row.user_id))
                    && (this.selected.seasons.length === 0 || this.selected.seasons.includes(row.season_id))

                )
            })
        },
        total_pages() {
            return Math.ceil(this.filtered_data.length / this.rows_per_page);
        }
    },
    mounted() {
        this.loadFilters();
        this.loadData();
    }
}
</script>

<template>
    <v-container fluid>
        <v-card class="mb-8 pa-4">
            <v-card-title>
                Фильтры
            </v-card-title>
            <v-row>
                <v-col class="v-col-lg-3">
                    <v-text-field label="Поиск" v-model="selected.search_text"></v-text-field>
                </v-col>
                <v-col class="v-col-lg-2">
                    <v-select label="Тип разметки" :items="filter_values.types" v-model="selected.types" multiple>
                    </v-select>
                </v-col>
                <v-col class="v-col-lg-2">
                    <v-select label="Сезоны" :items="filter_values.seasons" v-model="selected.seasons" multiple
                    item-value="season_id" item-title="title">
                    </v-select>
                </v-col>
                <v-col class="v-col-lg-3">
                    <v-select label="Разметчицы" :items="filter_values.users" v-model="selected.users" multiple>
                    </v-select>
                </v-col>
                <v-col class="v-col-lg-2">
                    <v-select label="Статус" :items="[{t:'Завершено', v:true}, {t:'В работе', v:false}]"
                              item-title="t" item-value="v" v-model="selected.completed" multiple
                    >
                    </v-select>
                </v-col>
            </v-row>
        </v-card>
        <v-card class="pa-4">
            <v-card-title>
                Разметка
            </v-card-title>
            <div class="d-flex justify-center" v-if="!is_data_loaded">
                <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate
                ></v-progress-circular>
            </div>
            <div v-else>
                <v-table
                    class="elevation-1"
                >
                    <thead>
                    <tr>
                        <th v-for="header in data_headers" :key="header.key">
                            {{ header.title }}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="row in view_data"
                        :key="row"
                        :class="{'completed': row.completed}"
                    >
                        <td v-for="header in data_headers" :key="header.key">
                            {{ row[header.key] }}
                        </td>
                    </tr>
                    </tbody>
                </v-table>
                <v-pagination
                  v-model="current_page"
                  :length="total_pages"
                  total-visible="6"
                ></v-pagination>
            </div>
        </v-card>
    </v-container>
</template>

<style scoped>
.completed {
    background-color: #A0D7A0;

}
.completed:hover {
    background-color: #6CD56C!important;
}
</style>