<template>
  <v-container fluid class="pa-0" style="position: absolute; top: 80px">
    <v-row
        class="ma-0 pa-0"
    >
      <v-col
          cols="8"
          class="d-flex flex-wrap pa-3 justify-space-between"
          offset="1"
      >
        <v-alert color="info" icon="$info"
                 max-width="380"
                 min-width="380"
                 max-height="380"
                 v-if="loadingState"
        style="text-align: center">
          <p style="text-align: left">Загрузка сообщений</p>
          <br><br>
          <v-progress-circular
              :size="70"
              :width="7"
              color="white"
              indeterminate
          ></v-progress-circular>
        </v-alert>
        <v-alert color="info" icon="$info"
                 max-width="380"
                 min-width="380"
                 max-height="380"
                 v-if="msgsList.length == 0 && !loadingState">
          <v-alert-title>Нет сообщений по вашему запросу</v-alert-title>
        </v-alert>
        <v-alert color="warning" icon="$warning"
                 max-width="380"
                 min-width="380"
                 max-height="380"
                 v-if="!minestery">
          Вы не выбрали министерство
        </v-alert>
        <v-alert color="warning" icon="$warning"
                 max-width="380"
                 min-width="380"
                 max-height="380"
                 v-if="!sub">
          Вы не выбрали сферу
        </v-alert>
        <v-alert color="error" icon="$error"
                 max-width="380"
                 min-width="380"
                 max-height="380"
                 v-if="countByTags>4">
          Вы не можете добавить больше сообщений по этому тегу
        </v-alert>
        <v-card
            class="pa-3 elevation-3 ma-3"
            outlined
            tile
            max-width="380"
            max-height="450"
            v-for="(msg, msgIndex) in msgsList" :key="msg.text"
        >
          <v-card-text>
            {{ msg.text }}
          </v-card-text>
          <v-card-actions>
            <v-card-text v-if="countByTags>4" style="color: red">Лимит по тегу</v-card-text>
            <v-spacer></v-spacer>
            <v-btn
                color="success"
                icon="mdi-plus"
                size="x-large"
                elevation="6"
                v-on:click="addMsg(msg.text, msgIndex)"
                v-if="minestery && sub && countByTags<5"
            ></v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
          cols="2"
      >
        <v-card class="pa-3" min-width="380">
          <v-card-title>Распределяем в...</v-card-title>
          <v-select
              :items="minsList"
              v-model="minestery"
              label="Министерство"
          ></v-select>
          <v-select
              :items="subsList[minestery]"
              v-model="sub"
              label="Сфера"
          ></v-select>
        </v-card>
        <v-card class="pa-3 mt-3" min-width="380">
          <v-card-title>Фильтры</v-card-title>
          <v-text-field class="my-0"
              label="Тег 1"
                        v-model="searchMsgTags[0]"
          ></v-text-field>
          <v-text-field class="my-0"
              label="Тег 2"
                        v-model="searchMsgTags[1]"
          ></v-text-field>
          <v-text-field class="my-0"
              label="Тег 3"
                        v-model="searchMsgTags[2]"
          ></v-text-field>
          <v-text-field class="my-0"
              label="Тег 4"
                        v-model="searchMsgTags[3]"
          ></v-text-field>
          <v-text-field class="my-0"
              label="Тег 5"
                        v-model="searchMsgTags[4]"
          ></v-text-field>
          <v-range-slider
              messages="Длинна сообщения"
              v-model="searchMsgLen"
              :max="5000"
              :min="0"
              step="1"
              thumb-label="always"
          ></v-range-slider>
          <v-card-text>По этим тегам уже добавлено {{countByTags}}</v-card-text>
          <v-btn block="" v-on:click="getData">Поиск</v-btn>
        </v-card>
        <v-card class="pa-3 mt-3" min-width="380">
          <v-card-title>Статистика</v-card-title>
          <v-table>
            <thead>
            <tr>
              <th class="text-left">
                Сфера
              </th>
              <th class="text-left">
                Количество
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="subC in countBySub" :key="subC.sub">
              <td>{{ subC.sub }}</td>
              <td>{{ subC.count }}</td>
            </tr>
            </tbody>
          </v-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
let apiUrl = process.env.VUE_APP_API_LINK

export default {
  name: "AdminSearchM",
  data: () => ({
    searchMsgLen: [100, 600],
    searchMsgTags: [""],
    msgsList: [],
    minsList: [
      'Минсельхоз'
    ],
    subsList: {
      'Минсельхоз': ["Поддержка сельхоз производителей (гранты и т.д.)","Правила содержания сельхоз животных на дачных участках","Выделение сельхоз земель","Вопросы/проблемы аграриев"],
    },
    minestery: "",
    sub: "",
    countBySub: [],
    countByTags: 0,
    loadingState: false
  }),
  methods: {
    getData: function () {
      this.loadingState = true
      this.msgsList = []
      this.getCountByTags()
      let searchTags = this.searchMsgTags
      axios({url: apiUrl + '/test',  headers: {"Authorization" : `Bearer ${this.$store.state.token}`}, method: 'POST', data: {
          tags: searchTags,
          minLen: this.searchMsgLen[0],
          maxLen: this.searchMsgLen[1]
        } })
          .then(resp => {
            this.loadingState = false
            this.msgsList = resp.data
          })
          .catch(err => {
            console.log(err)
          })
    },
    addMsg: function (text, msgIndex) {
      this.getCountByTags()
      this.msgsList.splice(msgIndex, 1)
      axios({url: apiUrl + '/addMsg',  headers: {"Authorization" : `Bearer ${this.$store.state.token}`}, method: 'POST', data: {
          text: text,
          sub: this.sub,
          minestery: this.minestery,
          tags: this.searchMsgTags
        } })
          .then(resp => {
            this.getCountBySub()
            console.log(resp)
          })
          .catch(err => {
            console.log(err)
          })
    },
    getCountBySub: function () {
      axios.get(apiUrl + "/countBySub", { headers: {"Authorization" : `Bearer ${this.$store.state.token}`} })
          .then(resp => {
            this.countBySub = resp.data
          })
    },
    getCountByTags: function () {
      let tags = this.searchMsgTags
      axios({url: apiUrl + "/countByTags", headers: {"Authorization" : `Bearer ${this.$store.state.token}`}, method: 'POST', data: {tags: tags} })
          .then(resp => {
            this.countByTags = resp.data[0].count
          })
    }
  },
  mounted() {
    this.getCountBySub()
  }
}
</script>

<style scoped>

</style>