<script>
import MarkupApi from "@/plugins/markup_api";

export default {
    name: "MarkupAdminControls",
    data: () => ({
        files: {
            messages: [],
            tasks: [],
            markup: []
        },
        is_uploading: false,
        seasons: [],
        new_season_dialog: false,
        new_season: {
            title: "",
            description: ""
        },
    }),
    emits: [
        "alert"
    ],
    methods: {
        uploadFile(target) {
            this.is_uploading = true;
            const blob = new Blob(this.files[target]);
            blob.text().then(fileData => {
                MarkupApi.post(`admin/upload`, this.$store.state.token, {file: fileData, type: target}, null, 30000).then(
                    resp => {
                        if (resp.data.complete) {
                            this.$emit("alert", "success", "Файл загружен", "Файл был успещно загружен");
                        } else {
                            this.$emit("alert", "error", "Ошибка", "Файл не был загружен: " + resp.data.error)
                        }
                        this.files[target] = [];
                        this.is_uploading = false;
                    }
                )
            })
        },
        loadSeasons() {
            MarkupApi.get(`admin/seasons`, this.$store.state.token,).then(
                resp => {
                    this.seasons = resp.data;
                }
            )
        },
        switchSeason(season_id) {
            MarkupApi.post(`admin/season-switch`, this.$store.state.token, {season_id: season_id}).then(() => this.loadSeasons());
        },
        createSeason() {
            MarkupApi.post('admin/season-new', this.$store.state.token, {
                title: this.new_season.title,
                description: this.new_season.description
            }).then(() => this.loadSeasons());
        }
    },
    mounted() {
        this.loadSeasons();
    }
}
</script>

<template>
    <v-container fluid>
        <v-card class="pa-4 mb-8">
            <v-card-title>
                Сезоны
            </v-card-title>
            <v-row>
                <v-col class="v-col-12">
                    <v-dialog
                        v-model="new_season_dialog"
                        width="auto"
                    >
                        <v-card class="pa-4">
                            <v-card-title>
                                Новый сезон
                            </v-card-title>
                            <v-text-field v-model="new_season.title" label="Название"></v-text-field>
                            <v-text-field v-model="new_season.description" label="Описание"></v-text-field>
                            <v-card-actions>
                                <v-btn color="primary" @click="createSeason();new_season_dialog = false"
                                       prepend-icon="mdi-check">Создать
                                </v-btn>
                                <v-btn color="red" @click="new_season_dialog = false" prepend-icon="mdi-close">Отмена
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-table class="elevation-1">
                        <thead>
                        <tr>
                            <th class="text-left">ID</th>
                            <th class="text-left">Название</th>
                            <th class="text-left">Описание</th>
                            <th class="text-left">Размечено</th>
                            <th class="text-left">Всего</th>
                            <th class="text-left">Статус</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="season in seasons"
                            :key="season.season_id"
                            :class="{'completed': season.current === season.total}">
                            <td>{{ season.season_id }}</td>
                            <td>{{ season.title }}</td>
                            <td>{{ season.description }}</td>
                            <td>{{ season.current }}</td>
                            <td>{{ season.total }}</td>
                            <td>
                                <v-checkbox v-model="season.status" style="margin: auto"
                                            @change="switchSeason(season.season_id)"></v-checkbox>
                            </td>
                        </tr>
                        </tbody>
                    </v-table>
                </v-col>
                <v-col class="d-flex justify-end">
                    <v-btn color="primary" @click="new_season_dialog=true;" prepend-icon="mdi-plus">Добавить</v-btn>
                </v-col>
            </v-row>
        </v-card>
        <v-card class="pa-4">
            <v-card-title>
                Загрузка данных
            </v-card-title>
            <v-row>
                <v-col class="v-col-xl-6" id="messages-upload">
                    <v-card elevation="3">
                        <v-card-title>
                            Сообщения
                        </v-card-title>
                        <v-card-text>
                            Файл должен содержать следующие колонки:
                            <br>
                            [text]
                        </v-card-text>
                        <v-row class="ml-2 mr-2">
                            <v-col class="v-col-lg-8">
                                <v-file-input v-model="files.messages" label="Файл .csv" accept=".csv"
                                              show-size></v-file-input>
                            </v-col>
                            <v-col class="v-col-lg-4 d-flex flex-column">
                                <v-btn v-if="!is_uploading"
                                       :disabled="files.messages.length < 1" prepend-icon="mdi-upload"
                                       class="flex-grow-1 mb-6" block @click="uploadFile('messages')"
                                >
                                    Загрузить
                                </v-btn>
                                <v-progress-circular
                                    v-else
                                    :size="50"
                                    color="primary"
                                    indeterminate
                                ></v-progress-circular>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col class="v-col-xl-6" id="tasks-upload">
                    <v-card elevation="3">
                        <v-card-title>
                            Задания
                        </v-card-title>
                        <v-card-text>
                            Файл должен содержать следующие колонки:
                            <br>
                            [message_id, type, condition]
                        </v-card-text>
                        <v-row class="ml-2 mr-2">
                            <v-col class="v-col-lg-8">
                                <v-file-input v-model="files.tasks" label="Файл .csv" accept=".csv"
                                              show-size></v-file-input>
                            </v-col>
                            <v-col class="v-col-lg-4 d-flex flex-column">
                                <v-btn v-if="!is_uploading"
                                       :disabled="files.tasks.length < 1" prepend-icon="mdi-upload"
                                       class="flex-grow-1 mb-6" block @click="uploadFile('tasks')"
                                >
                                    Загрузить
                                </v-btn>
                                <v-progress-circular
                                    v-else
                                    :size="50"
                                    color="primary"
                                    indeterminate
                                ></v-progress-circular>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col class="v-col-xl-6" id="markup-upload">
                    <v-card elevation="3">
                        <v-card-title>
                            Разметки
                        </v-card-title>
                        <v-card-text>
                            Файл должен содержать следующие колонки:
                            <br>
                            [task_id, user_id, priority, season_id]
                        </v-card-text>
                        <v-row class="ml-2 mr-2">
                            <v-col class="v-col-lg-8">
                                <v-file-input v-model="files.markup" label="Файл .csv" accept=".csv"
                                              show-size></v-file-input>
                            </v-col>
                            <v-col class="v-col-lg-4 d-flex flex-column">
                                <v-btn v-if="!is_uploading"
                                       :disabled="files.markup.length < 1" prepend-icon="mdi-upload"
                                       class="flex-grow-1 mb-6" block @click="uploadFile('markup')"
                                >
                                    Загрузить
                                </v-btn>
                                <v-progress-circular
                                    v-else
                                    :size="50"
                                    color="primary"
                                    indeterminate
                                ></v-progress-circular>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<style scoped>
.completed {
    background-color: #A0D7A0;

}
.completed:hover {
    background-color: #6CD56C!important;
}
</style>