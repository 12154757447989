<template>
    <alerts-list ref="alerts"></alerts-list>
    <v-container fluid class="pa-0" style="position: absolute; top: 80px">
        <v-row class="ma-0 pa-0">
            <v-col cols="10" offset="1">
                <markup-parts-statistics ref="statistics" :markup_type="markup_type" :error_alert="errorAlert"/>
                <markup-parts-body :is_loaded="is_loaded" :message="message" :instruction="instruction">
                    <template v-slot:markup>
                        <v-divider></v-divider>
                        <v-row>
                            <v-col><h4>Выбранные эмоции</h4></v-col>
                        </v-row>
                        <v-row class="ma-1">
                            <v-col>
                                <v-chip
                                    v-for="emotion_id in markup"
                                    :key="emotion_id"
                                    class="ma-1" variant="outlined"
                                    :color="emotionColor(emotion_id)"
                                    v-on:click="switchEmotion(emotion_id)"
                                >
                                    {{this.emotions_list.filter((e) => e.id == emotion_id)[0].name}}
                                </v-chip>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                            <v-col><h4>Другие эмоции</h4></v-col>
                        </v-row>
                        <v-row class="ma-1">
                            <v-col>
                                <v-chip
                                    class="ma-1" variant="outlined"
                                    :color="emotionColor(emotion.id)"
                                    v-on:click="switchEmotion(emotion.id)"
                                    v-for="emotion in this.emotions_list.filter((e) => !markup.includes(e.id))"
                                    :key="emotion.id"
                                >
                                    {{ emotion.name }}
                                </v-chip>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:controls>
                        <v-row>
                            <v-col>
                                <v-btn
                                    color="info"
                                    v-on:click="moveBack()"
                                    :disabled="previous_markup_id == null"
                                >
                                    Назад
                                </v-btn>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col>
                                <v-btn density="default" color="green" class="float-right"
                                       v-on:click="setMarkup(markup)"
                                >
                                    Сохранить
                                </v-btn>
                            </v-col>
                        </v-row>
                    </template>
                </markup-parts-body>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import MarkupEmotionBase from "@/components/Markup/Emotion/Base.vue";
import AlertsList from "@/components/AlertsList.vue";
import MarkupPartsStatistics from "@/components/Markup/Parts/Statistics.vue";
import MarkupPartsBody from "@/components/Markup/Parts/Body.vue";


export default {
    name: "MarkupEmotionSelect",
    components: {AlertsList, MarkupPartsStatistics, MarkupPartsBody,},
    extends: MarkupEmotionBase,
    data: () => ({
        markup_type: "EmotionSelect"
    }),
    methods: {
        emotionColor: function (emotion_id) {
            if (this.markup.includes(emotion_id)) {
                return 'green';
            } else {
                return 'red';
            }
        },
        switchEmotion: function (emotion_id) {
            if (this.markup.includes(emotion_id)) {
                this.markup = this.markup.filter((tid) => tid !== emotion_id);
            } else {
                this.markup.push(emotion_id);
            }
        },
        defaultMarkup: function () {
            return [];
        },
    }
}
</script>